<template>
  <section class="analytics-dashboard">
    <div v-if="activeCampaign">
      <div class="analytics--top-bar">
        <div class="analytics--campaign-row-wrapper">
          <campaign-row
            :campaign="activeCampaign"
            :creative-count="activeCampaign.creative_count"
            :dates="true"
            :details="false"
            :prevent-click="true"
            @click.prevent.stop="showCampaignSearch"
          />
          <icon
            name="arrow_down"
            @click.prevent.stop="showCampaignSearch"
          />
          <popup-with-search
            v-if="campaignSearchVisible"
            endpoint="/api/search"
            :query-params="['force=true', 'limit=10',`showcase=${isRunningIntercomTour}`, 'sortBy=last_edit', 'sortWay=DESC' ]"
            :should-debounce-search="true"
          >
            <template #default="{ data }">
              <div class="analytics--campaign-search-result">
                <template
                  v-for="campaign in data.campaigns"
                  :key="`campaign-search-${campaign.uuid}`"
                >
                  <campaign-row
                    :campaign="campaign"
                    :creative-count="campaign.creative_count"
                    :dates="false"
                    :details="true"
                    :prevent-click="true"
                    @click.prevent.stop="redirectToCampaign(campaign)"
                  />
                  <div class="line-through"></div>
                </template>
              </div>
            </template>
          </popup-with-search>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <date-range-picker
            v-if="isDatepickerShown"
            ref="campaign-datepicker"
            :calendar-count="1"
            icon="arrow_down"
            :max-width="315"
            :min-width="315"
            @change="changeTimeRange"
          />
          <input-dropdown
            v-else
            ref="campaign-dropdown"
            v-model:value="selectedDuration"
            :max-width="315"
            :min-width="315"
            :options="availableDurations"
            :placeholder="selectedDurationPlaceholder"
            :placement="'bottom-end'"
            @input="changeDuration"
          />
          <danger-button
            v-if="automaticReportSubscribed"
            icon="close"
            style="margin: 0 20px"
            @click="openRemoveAutomaticReportsModal"
          >
            <span>Automatic Reports</span>
          </danger-button>
          <primary-button
            v-else
            icon="add"
            style="margin: 0 20px"
            @click="openAutomaticReportsModal"
          >
            <span>Automatic Reports</span>
          </primary-button>
          <div
            v-if="rowActions.length > 0"
            class="dropdown-holder"
            @click.prevent.stop
          >
            <context-dropdown
              :min-width="182"
              :options="rowActions"
              :placement="'bottom-end'"
            />
          </div>
        </div>
      </div>
      <template v-if="areThereAnyDesigns">
        <analytics-stat-cards
          :analytics="campaignAnalytics"
          :campaign="activeCampaign"
          class="analytics--stat-card-row"
        />
        <creatives-list
          :campaign="activeCampaign"
          :current-page="currentPage"
          :designs="designs"
          :end-date="endDate"
          :last-page="lastPage"
          :selected-design="activeDesign"
          :selected-tag="selectedTag"
          :start-date="startDate"
          @load-next-page="loadNextPage"
          @select-design-tag="selectDesignTag"
        />
      </template>

      <empty-section
        v-else
        :icon="
          require('../../../images/dashboard/designer/creatives-empty-state.svg')
        "
      >
        There are no Live Creatives yet. Start by publishing a new one!
      </empty-section>

      <creative-overview
        v-if="shouldShowCreativeOverview"
        :key="
          activeDesign.uuid +
            '-' +
            selectedTag.uuid +
            '-' +
            startDate +
            '-' +
            endDate
        "
        :campaign="activeCampaign"
        :design="activeDesign"
        :design-analytics="designAnalytics"
        :end-date="endDate"
        :start-date="startDate"
        :tag="selectedTag"
      />
      <empty-section
        v-if="!shouldShowCreativeOverview && areThereAnyDesigns"
        :icon="
          require('../../../images/dashboard/manager/impressions-empty-state.svg')
        "
      >
        Looks like there are <br />
        no Creative Data
      </empty-section>
    </div>
    <empty-section
      v-else
      :icon="
        require('../../../images/dashboard/designer/campaigns-empty-state.svg')
      "
    >
      There are no Live Campaigns yet. Start by creating new one!
    </empty-section>
  </section>
</template>
<script>
import CreativeOverview from "./components/CreativeOverview"
import PopupWithSearch from "../common/PopupWithSearch"
import EmptySection from "../dashboard/components/EmptySection"
import DateRangePicker from "../common/DateRangePicker"
import InputDropdown from "../common/dropdown/InputDropdown"
import AutomaticReportModal from "../modals/AutomaticReportModal"
import DeleteModal from "../common/modal/DeleteModal"
import AnalyticsStatCards from "./components/AnalyticsStatCards"
import CreativesList from "./components/CreativesList"
import ContextDropdown from "@/components/common/dropdown/ContextDropdown.vue"

import Language from "../../i18n/en"
import Utils from "../../utils"
import dayjs from "dayjs"

import { AnalyticsRoutes } from "../../api/routes"

export default {
  components: {
    ContextDropdown,
    AnalyticsStatCards,
    PopupWithSearch,
    CreativesList,
    CreativeOverview,
    EmptySection,
    DateRangePicker,
    InputDropdown
  },
  props: {
    campaignId: {
      type: String,
      default: "latest"
    }
  },
  data () {
    return {
      automaticReportSubscribed: null,
      campaignSearchVisible: false,
      isDatepickerShown: false,
      selectedDuration: { title: "All time", value: "all_time" },
      selectedDurationPlaceholder: "",
      availableDurations: [
        { title: "Campaign Duration", value: "campaign" },
        { title: "Current month", value: "current_month" },
        { title: "Last month", value: "last_month" },
        { title: "Current year", value: "current_year" },
        { title: "Last year", value: "last_year" },
        { title: "All time", value: "all_time" },
        { title: "Custom range", value: "custom" }
      ],
      activeDesign: null,
      activeCampaign: null,
      selectedTag: null,
      analytics: {},
      campaignAnalytics: {},
      designs: [],
      startDate: null,
      endDate: null,
      designAnalytics: {},
      fakeData: 0,
      currentPage: 1,
      pageSize: 5,
      lastPage: 1,
      currentSort: "created_at",
      currentSortDir: "DESC",
      filters: {
        archived: false
      }
    }
  },
  computed: {
    rowActions () {
      const actions = []
      actions.push({
        content: "Download CSV",
        icon: "download",
        click: () => this.downloadCampaignReport(this.activeCampaign, "csv")
      })
      actions.push({
        content: "Download XLS",
        icon: "download",
        click: () => this.downloadCampaignReport(this.activeCampaign, "xlsx")
      })

      return actions
    },
    shouldShowCreativeOverview () {
      return this.activeDesign?.adtags?.length
    },
    areThereAnyDesigns () {
      return Boolean(this.designs?.length)
    },
    isRunningIntercomTour () {
      return window.isRunningIntercomTour
    },
    Utils () {
      return Utils
    }
  },
  beforeUnmount () {
    window.removeEventListener(
      "intercomTourChanged",
      this.loadDataFromActiveCampaign
    )
    this.$eventHub.$off("hide-search", this.hideCampaignSearch)
  },
  created () {
    window.addEventListener(
      "intercomTourChanged",
      this.loadDataFromActiveCampaign
    )
    this.$eventHub.$on("hide-search", this.hideCampaignSearch)
    this.loadDataFromActiveCampaign()
  },
  methods: {
    loadNextPage () {
      this.currentPage++
      this.fetchDesigns(
        this.currentPage,
        this.pageSize,
        this.currentSort,
        this.currentSortDir
      ).then((resp) => {
        this.currentPage = resp.data.meta.current_page
        this.lastPage = resp.data.meta.last_page
        this.designs = Utils.removeDuplicatesByKey(
          [...this.designs, ...resp.data.data],
          "uuid"
        )
      })
    },
    getFakeData () {
      const params = Utils.getUrlParameters(location.search || "")
      return window.isRunningIntercomTour === true
        ? 1
        : params && params.fake
          ? parseInt(params.fake)
          : 0
    },
    selectDesignTag (design, tag) {
      this.activeDesign = design
      if (!tag && design.adtags) {
        this.selectedTag = design.adtags[0]
      } else {
        this.selectedTag = tag
      }

      this.loadDesignAnalytics()
    },
    loadDesignAnalytics () {
      if (this.selectedTag && this.activeDesign) {
        return this.$apiClient
          .get(AnalyticsRoutes.getChartDataGeneral(), {
            params: {
              fake: this.getFakeData(),
              start: this.startDate,
              end: this.endDate,
              tagId: this.selectedTag.uuid,
              design: this.activeDesign.uuid
            }
          })
          .then((resp) => {
            this.designAnalytics = resp.data
          })
      }
    },
    openRemoveAutomaticReportsModal () {
      this.$modal.show(DeleteModal, {
        title: "Unsubscribe Automatic Reports",
        description:
          "Are you sure you want to unsubscribe to receive reports for this campaign? This cannot be undone.",
        actionButton: "Unsubscribe",
        successMessage:
          Language.analytics.success.automatic_report_unsubscribed,
        errorMessage: Language.analytics.error.automatic_report_unsubscribed,
        endpoint: AnalyticsRoutes.deleteAutomaticReport(
          this.activeCampaign ? this.activeCampaign.unique_id : this.campaignId
        ),
        afterDelete: this.loadAutomaticReport
      })
    },
    openAutomaticReportsModal () {
      this.$modal.show(AutomaticReportModal, {
        report: this.automaticReportSubscribed,
        campaignId: this.activeCampaign
          ? this.activeCampaign.unique_id
          : this.campaignId,
        afterSubmit: this.loadAutomaticReport
      })
    },
    changeTimeRange (data) {
      if (data.length) {
        this.startDate = data[0].toLocaleDateString("en-US")
        this.endDate = data[1].toLocaleDateString("en-US")
        this.$refs["campaign-datepicker"].closeCalendar()
        this.loadCampaignAnalytics()
        this.loadDesignAnalytics()
        this.datePickerClicked()
      }
    },
    datePickerClicked () {
      this.isDatepickerShown = false
      this.$nextTick(() => {
        this.selectedDurationPlaceholder =
          this.startDate + " - " + this.endDate
        this.selectedDuration = null
      })
    },
    changeDuration (option) {
      let start = ""
      let end = ""
      const format = "YYYY-MM-DD"
      switch (option.value) {
        case "campaign":
          start = dayjs(this.activeCampaign.campaign_start).format(format)
          end = dayjs(this.activeCampaign.campaign_end).format(format)
          break
        case "current_month":
          start = dayjs().startOf("month").format(format)
          end = dayjs().endOf("month").format(format)
          break
        case "last_month":
          start = dayjs().subtract(1, "month").startOf("month").format(format)
          end = dayjs().subtract(1, "month").endOf("month").format(format)
          break
        case "current_year":
          start = dayjs().startOf("year").format(format)
          end = dayjs().endOf("year").format(format)
          break
        case "last_year":
          start = dayjs().subtract(1, "year").startOf("year").format(format)
          end = dayjs().subtract(1, "year").endOf("year").format(format)
          break
        case "all_time":
          start = dayjs(this.activeCampaign.campaign_start).format(format)
          end = dayjs().subtract(0, "year").endOf("year").format(format)
          break
        case "custom":
          this.isDatepickerShown = true
          this.$nextTick(() => {
            this.$refs["campaign-datepicker"].openCalendar()
          })
          break
      }

      if (start && end) {
        this.startDate = start
        this.endDate = end
        this.loadCampaignAnalytics()
        this.loadDesignAnalytics()
      }
    },
    hideCampaignSearch () {
      this.campaignSearchVisible = false
    },
    loadDataFromActiveCampaign () {
      this.loadData(this.campaignId)
    },
    loadAutomaticReport () {
      return this.$apiClient
        .get(
          AnalyticsRoutes.getAutomaticReport(
            this.activeCampaign
              ? this.activeCampaign.unique_id
              : this.campaignId, {
              filters: this.filters
            }
          ),
          []
        )
        .then((resp) => {
          this.automaticReportSubscribed = resp.data.data
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            this.automaticReportSubscribed = null
          }
        })
    },
    loadData (campaignId) {
      if (this.getFakeData()) {
        this.startDate = dayjs().startOf("month").format("YYYY-MM-DD")
        this.endDate = dayjs().endOf("month").format("YYYY-MM-DD")
      }

      this.$store
        .dispatch("loadCampaign", campaignId, false)
        .then((campaign) => {
          this.activeCampaign = campaign
          this.startDate = dayjs(this.activeCampaign.campaign_start).format("YYYY-MM-DD")
          this.endDate = dayjs()
            .subtract(0, "year")
            .endOf("year")
            .format("YYYY-MM-DD")

          this.loadCampaignAnalytics()
          this.fetchDesigns(
            1,
            this.pageSize,
            this.currentSort,
            this.currentSortDir
          ).then((resp) => {
            this.currentPage = resp.data.meta.current_page
            this.lastPage = resp.data.meta.last_page
            this.designs = resp.data.data

            let tagFound = false
            if (this.designs.length) {
              for (const design of this.designs) {
                if (design.adtags.length) {
                  tagFound = true
                  this.selectDesignTag(design, design.adtags[0])
                  break
                }
              }
            }

            if (!tagFound) {
              this.activeDesign = null
              this.selectedTag = null
              this.designAnalytics = null
            }
          })
          this.loadAutomaticReport()
        })
    },
    redirectToCampaign (campaign) {
      if (
        this.activeCampaign &&
        this.activeCampaign.unique_id !== campaign.unique_id
      ) {
        this.$router.push({
          name: "campaign.analytics",
          params: { campaignUniqueId: campaign.unique_id }
        })
        this.loadData(campaign.unique_id)
      }

      this.hideCampaignSearch()
    },
    showCampaignSearch () {
      this.campaignSearchVisible = !this.campaignSearchVisible
    },
    loadCampaignAnalytics () {
      if (this.activeCampaign) {
        return this.$apiClient
          .get(AnalyticsRoutes.getChartDataGeneral(), {
            params: {
              fake: this.getFakeData(),
              start: this.startDate,
              end: this.endDate,
              campaign: this.activeCampaign.uuid,
              filters: this.filters
            }
          })
          .then((resp) => {
            this.campaignAnalytics = resp.data
          })
      }
    },
    downloadCampaignReport (campaign, type = "csv") {
      const route =
        type === "csv"
          ? AnalyticsRoutes.getCampaignAnalyticsCsv(campaign.uuid)
          : AnalyticsRoutes.getCampaignAnalyticsXslx(campaign.uuid)
      this.$apiClient
        .get(route, {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            filters: this.filters
          },
          responseType: "arraybuffer"
        })
        .then((response) => {
          Utils.downloadFileFromResponse(response)
          return response
        })
    },
    fetchDesigns (page, limit, sortBy, sortWay) {
      const payload = {
        campaignUuid: this.activeCampaign.uuid,
        limit,
        token: this.activeCampaign.preview_token,
        page,
        sortBy,
        sortWay,
        performCommit: false,
        filters: this.filters
      }

      return this.$store.dispatch("loadAdTagDesigns", payload)
    }
  }
}
</script>
