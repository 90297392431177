export const Language = {
  cancel: "Cancel",
  confirm: "Confirm",
  confirm_delete: "Confirm",
  expired_session: "Your session has expired. Please reload and sign in again",
  file_too_big: "Uploaded file is too big",
  unsupported_file_type: "Unsupported file type",
  quota_exceeded: "Quota exceed",
  request_generic_error: "Request couldn't be made",
  wrong_permissions: "You do not have proper permissions",
  login: {
    success: {
      login: "Logged in successfully"
    },
    error: {
      internal: "Internal server error",
      email_empty: "Email address is required",
      invalid_email: "Invalid email address",
      password_empty: "Password is required"
    }
  },
  analytics: {
    success: {
      automatic_report_saved: "Automatic report saved successfully.",
      automatic_report_unsubscribed: "Automatic report has been unsubscribed for this campaign.",
      comprehensive_report_generated: "Comprehensive report generated successfully."
    },
    error: {
      automatic_report_saved: "Please select a date range and at least one campaign first!",
      automatic_report_unsubscribed: "There was an error while unsubscribing automatic report.",
      comprehensive_report_generated: "There was an error while generating comprehensive report."
    }
  },
  password_reset: {
    success: {
      instructions_sent:
        "If such email is in our database, we'll send an email with the password recovery instructions.",
      instructions_sent_profile:
        "We'll send an email with the password recovery instructions."
    },
    error: {
      instructions_error: "Cannot send the password reset instructions",
      internal: "Server error while resetting password"
    }
  },
  invite: {
    error: {
      internal: "Server error while accepting invitation"
    }
  },
  templates: {
    success: {
      delete: "Template removed",
      create: "Template has been successfully created"
    },
    error: {
      delete: "Template could not be removed.",
      create: "Template was not created"
    }
  },
  users: {
    success: {
      create: "User has been successfully created",
      edit: "User has been successfully updated",
      invitation: "Invitation has been successfully sent",
      delete: "User deleted successfully",
      company_data: "Company data has been successfully updated",
      payment_data: "Payment data has been successfully updated"
    },
    error: {
      create: "Error creating new user",
      edit: "Error while updating user data",
      company_data: "Error while updating company data",
      payment_data: "Error while updating payment data",
      invitation: "Error while sending invitation",
      delete: "Error while deleting user data"
    }
  },
  brands: {
    success: {
      delete: "Brand removed"
    },
    error: {
      delete: "Brand could not be removed"
    }
  },
  campaign: {
    success: {
      duplicate: "Campaign has been duplicated",
      create: "Campaign has been successfully created",
      edit: "Campaign has been successfully updated",
      access: "Campaign access has been successfully modified",
      delete: "Campaign removed",
      delete_tag: "Tag has been removed",
      archived: "Campaign has been successfully archived",
      unarchived: "Campaign has been successfully unarchived"
    },
    error: {
      duplicate: "Campaign could not be duplicated",
      create: "Campaign was not created",
      delete_tag: "Tag could not been removed",
      edit: "Campaign was not updated",
      access: "Campaign access was not modified",
      delete: "Campaign could not be removed",
      archived: "Campaign could not be archived",
      unarchived: "Campaign could not be unarchived"
    }
  },
  invoice: {
    success: {
      create: "Invoice has been successfully created",
      edit: "Invoice has been successfully updated"
    },
    error: {
      create: "Invoice was not created",
      edit: "Invoice was not updated"
    }
  },
  company: {
    success: {
      create: "Company has been successfully created",
      edit: "Company has been successfully updated",
      delete: "Company has been successfully deleted",
      archived: "Company archived",
      unarchived: "Company unarchived",
      edit_members: "Company members has been successfully modified",
      edit_features: "Company features have been successfully updated"
    },
    error: {
      create: "Company was not created",
      edit: "Company was not updated",
      delete: "Company could not be deleted",
      archived: "Company could not be archived",
      unarchived: "Company could not be unarchived",
      edit_members: "Company members was not modified",
      edit_features: "Company features weren't updated"
    }
  },
  modules: {
    error: {
      module_excluded: "Module cannot be moved (limit reached)"
    }
  },
  assets: {
    success: {
      delete:
        "Asset removed. Remember to save design to remove it from modules!",
      delete_multiple:
        "Assets removed. Remember to save design to remove it from modules!",
      rename: "Design has been successfully renamed",
      convert_png_to_jpg: "PNG converted to JPG.",
      compress_and_save: "Image compressed and saved"
    },
    error: {
      delete: "Asset could not be removed",
      not_uploaded: "Asset couldn't be uploaded",
      drop_limitation: "Asset cannot be dropped (limit reached)",
      file_ratio_invalid: "Asset cannot be uploaded, because of wrong ratio.",
      file_limit_exceeded: "Couldn’t upload assets - please limit your uploads to 100 files.",
      convert_png_to_jpg: "Error converting PNG to JPG.",
      compress_image: "Error compressing image."
    },
    warning: {
      image_too_big: "was over 4 MB, so it wasn't uploaded.",
      video_too_big: "was over 10 MB, so it wasn't uploaded."
    }
  },
  designs: {
    success: {
      fetch: "Design is downloading from repository",
      duplicate: "Design has been duplicated",
      create: "Design has been successfully created",
      delete: "Design has been successfully deleted",
      event_delete: "Event has been successfully deleted",
      timeline_tab_delete: "Timeline tab has been successfully deleted",
      scene_delete: "Scene has been successfully deleted",
      push_started: "Design push to repository has been started",
      converted: "Design has been converted to external ad type",
      host_started:
        "Design hosting has been started. After finished you should receive a campaign link on your email address.",
      unhost_started:
        "Design hosting has been cancelled. After finished you should receive a confirmation sent to your email address.",
      build_started: "Design build has been started",
      pack_started:
        "Design packing has been started. The download will start automatically after finish, do not close this tab.",
      pack_finished: "Your package was successfully created.\nIf the download doesn't start in 5 Seconds use the Package download button.",
      import_started:
        "Design import has been started. You will be informed about the progress in the notification.",
      import_design_finished:
        "Design import has been finished successfully.",
      import_designs_finished:
        "Designs import has been finished successfully.",
      import_campaign_designs_from_another_company_started:
        "Campaign designs import from another company has been started. If you have a lot of designs, it may take a while.",
      import_campaign_finished: "Campaign import has been finished successfully.",
      save: "Design has been successfully saved",
      cropped: "Cropped image has been successfully saved",
      folder_deleted: "Folder has been removed.",
      sent_to_review: "Design has been sent to review",
      archived: "Design has been successfully archived",
      unarchived: "Design has been successfully unarchived",
      change_design_name: "Design name has been successfully changed."
    },
    error: {
      fetch: "Design could not be downloaded from repository",
      delete: "Design could not be deleted",
      create: "Design could not be created",
      event_delete: "Event could not be deleted",
      timeline_tab_delete: "Timeline tab could not be deleted",
      scene_delete: "Scene could not be deleted",
      import_failed: "Design import failed. Verify provided data and try again.",
      duplicate: "Design could not be duplicated",
      push_started: "Design could not be pushed to repository",
      converted: "Design could not been converted to external ad type",
      host_started: "Design could not be hosted",
      build_started: "Design could not be built",
      pack_started: "Design could not be packed",
      pack_failed: "Something went wrong. Please start your package job again.",
      save: "Design could not be saved",
      cropped: "Cropped image could not be saved",
      sent_to_review: "Design could not be sent to review",
      archived: "Design could not be archived",
      unarchived: "Design could not be unarchived",
      change_design_name: "Design name could not be changed."
    },
    info: {
      time_spent: "Time spent on building this ad: ",
      autosave_started: "Autosaving...",
      missing_fallback_modal: "The design is missing a fallback image. You can set this up in the \"Specs\" tab",
      missing_fallback_modal_title: "Missing fallback image",
      missing_fallback_modal_proceed: "Proceed anyway",
      dooh_video_generated: "DOOH Video is available for download",
      confirm_timeline_tab_delete: "Are you sure to delete this timeline tab?"
    }
  },
  events: {
    info: {
      confirm_delete: "Are you sure to delete this event?",
      scroll_trigger_event_added_showroom_info: "We have updated your showroom to use interscroller preview. If you want to disable it, please go to the specs tab."
    },
    success: {
      create: "Event has been successfully added.",
      edit: "Event has been successfully updated."
    },
    error: {
      stop_animation_target_missing: "Stop animation target is missing.",
      initiator_missing: "Event initiator is missing.",
      trigger_missing: "Event trigger is missing.",
      action_missing: "Event action is missing.",
      sync_event_missing: "Synced event is missing.",
      scene_missing: "Scene is missing.",
      group_missing: "Group is missing.",
      tracking_missing: "Tracking url is missing.",
      video_missing: "Video is missing.",
      map_missing: "Map module is missing.",
      slidebar_missing: "Slidebar is missing.",
      counter_missing: "Counter is missing.",
      animation_missing: "Animation name is missing.",
      animation_duration_invalid:
        "Animation duration must be longer than 0 seconds.",
      target_missing: "You have to select target.",
      match_target_missing: "You have to select a match target.",
      animation_target_missing: "Animation target is missing.",
      effect_missing: "Target effect is missing.",
      timer_missing: "Timer module is not selected.",
      scene_transition_is_missing: "Scene transition is not selected."
    }
  },
  settings: {
    success: {
      save: "Settings has been successfully saved"
    },
    error: {
      save: "Error when saving settings",
      screenshot:
        "There was an error creating screenshot. Please try again later."
    },
    info: {
      screenshot: "The screenshot will be taken soon",
      screenshot_ready: "Screenshot ready and set as fallback image"
    }
  },
  groups: {
    success: {
      create: "Group has been successfully created",
      edit: "Group has been successfully modified"
    },
    error: {
      create: "Group was not created",
      edit: "Group was not modified"
    }
  },
  reports: {
    success: {
      create: "Report has been successfully created"
    },
    error: {
      create: "Report was not send"
    }
  },
  comments: {
    success: {
      create: "Comment sent!"
    },
    error: {
      content_missing: "Enter a comment message before sending"
    }
  },
  adtags: {
    success: {
      share: "AdTag has been shared",
      create: "AdTag successfully created",
      update: "AdTag successfully saved",
      delete: "AdTag successfully deleted"
    },
    error: {
      share: "Could not share AdTag",
      download: "Could not download AdTags",
      create: "Could not create AdTag",
      update: "Could not save AdTag",
      delete: "Could not delete AdTag"
    },
    info: {
      design_unhosted: (designName) => `Design ${designName} was unhosted`
    }
  },
  showroom: {
    success: {
      approve: "Design approved",
      reject: "Design rejected",
      share: "Design has been shared"
    },
    error: {
      approve: "Could not approve the design",
      reject: "Could not reject the design",
      share: "Could not share the design"
    }
  },
  clipboard: {
    success: {
      copy_link: "Your Link was successfully copied"
    }
  },
  profile: {
    success: {
      update: "Your profile has been successfully updated",
      email:
        "The verification emails has been sent to your old and new email address. Please confirm them on both inboxes to approve the e-mail change",
      settings: "Your profile settings has been successfully updated"
    },
    error: {
      update: "Could not update your profile",
      email: "Could not send the verification emails",
      settings: "Could not update your profile settings"
    }
  },
  intercom: {
    fail: "Intercom integration couldn't be initialized"
  },
  editor: {
    swiper_group_module: {
      info: {
        looped_carousel_with_3_slides: "A gallery carousel effect with looped slides must consist of at least 4 slides."
      }
    },
    error: {
      duplicate_not_allowed: "Module cannot be duplicated.",
      design_not_found: "Design not found"
    },
    destination: {
      info: {
        on_save: "Destination saving process takes a few minutes - please be patient."
      },
      parse: {
        fail: "Parsing CSV file failed, please use comma or semicolon as a separator"
      },
      reminder: "By default, data binding items are hidden. Remember to unhide them in the layers tab."
    },
    googleMaps: {
      parse: {
        fail: "Parsing CSV file failed, please use comma or semicolon as a separator"
      }
    },
    wagawin_wizard: {
      success: {

      },
      error: {
        card_not_square: "The ratio of the card file is not 1:1"
      }
    },
    poll: {
      confirm_reset: {
        modal_title: "Confirm poll result reset",
        modal_description: "Are you sure? All poll results are reset and permanently deleted ",
        action_button: "Reset"
      },
      success: {
        poll_module_registered_in_the_database: "Poll module successfully registered in the database.",
        poll_module_reset_database: "Poll module has successfully reset poll database."
      },
      error: {
        failed_to_register_poll_module_in_the_database: "Failed to register a poll module in the database. Please try to re-register module using Register button in the Poll settings.",
        failed_to_poll_database: "Failed to reset poll database."
      }
    },
    wagawin_video_poll: {
      success: {
        poll_module_registered_in_the_database: "Video poll module successfully registered in the database.",
        poll_module_reset_database: "Video poll module has successfully reset poll database."
      },
      error: {
        failed_to_register_video_poll_module_in_the_database: "Failed to register a video poll module in the database. Please try to re-register module using Register button in the Poll settings.",
        failed_to_poll_database: "Failed to reset a video poll database."
      }
    },
    survey: {
      confirm_reset: {
        modal_title: "Confirm survey result reset",
        modal_description: "Are you sure? All survey results are reset and permanently deleted ",
        action_button: "Reset"
      },
      success: {
        survey_module_registered_in_the_database: "Survey module successfully registered in the database.",
        survey_module_reset_database: "Survey module has successfully reset survey database."
      },
      error: {
        failed_to_register_survey_module_in_the_database: "Failed to register a survey module in the database. Please try to re-register module using Register button in the Survey settings.",
        failed_to_survey_database: "Failed to reset survey database."
      }
    },
    offerista: {
      error: {
        products_limit_reached: "The maximum product limit has been reached.",
        brochure_not_fetched: "Brochure couldn't be fetched, please try again. ",
        products_not_fetched: "Products couldn't be fetched, please try again. "
      },
      info: {
        initial_offerista_data_fetching: "Offerista is registering, please wait 2 minutes before saving.",
        initial_offerista_data_fetching_finished: "Offerista has been registered.",
        initial_offerista_data_fetching_in_progress: "Offerista is still fetching its data - please be patient.",
        products_updated: "Offerista has updated its products.",
        categories_updated: "Offerista has updated its categories.",
        banners_missing: "Offerista banners are missing"
      }
    }
  }
}

export default Language
