import {
  AssetModuleType,
  BackgroundModuleType,
  BasketModuleType,
  CalendarModuleType,
  Click2MapsModuleType,
  CountdownModuleType,
  GoogleMapsModuleType,
  HotOrNotModuleType,
  ImageSequenceModuleType,
  LightweightSwiperGroupModuleType,
  MatchModuleType,
  MemoryModuleType,
  OfferistaModuleType,
  PanoModuleType,
  ParticleWipeAdModuleType,
  PollModuleType,
  PopupModuleType,
  PuzzleModuleType,
  ShakeModuleType,
  SlidebarModuleType,
  SliderModuleType,
  StoryModuleType,
  SurveyModuleType,
  SwiperGroupModuleType,
  TimerModuleType,
  TypoModuleType,
  VideoModuleType,
  VideoStoryModuleType,
  WhatsappModuleType,
  WipeAdModuleType,
  VastVideoModuleType,
  CounterModuleType,
  CatchGameModuleType,
  ThreeDModuleType
} from "@/components/designer/module_types/types"
import Utils from "@/utils"
import { AnimationManager } from "@/classes/animationManager"

export class EventManager {
  /**
   * generic available triggers for every module
   * @return {{[p: string]: string}}
   * @constructor
   */
  static get AVAILABLE_TRIGGERS () {
    return {
      [EventManager.TRIGGER_CLICK]: "On click",
      [EventManager.TRIGGER_HOVER]: "On hover",
      [EventManager.TRIGGER_SWIPE_LEFT]: "On swipe left",
      [EventManager.TRIGGER_SWIPE_RIGHT]: "On swipe right",
      [EventManager.TRIGGER_SWIPE_UP]: "On swipe up",
      [EventManager.TRIGGER_SWIPE_DOWN]: "On swipe down",
      [EventManager.TRIGGER_LONGPRESS]: "On longpress",
      [EventManager.TRIGGER_TARGET_ONLOAD]: "On module load"
    }
  }

  static get CLICKABLE_TRIGGERS () {
    return [
      EventManager.TRIGGER_CLICK,
      EventManager.TRIGGER_SWIPE_LEFT,
      EventManager.TRIGGER_SWIPE_RIGHT,
      EventManager.TRIGGER_SWIPE_UP,
      EventManager.TRIGGER_SWIPE_DOWN,
      EventManager.TRIGGER_LONGPRESS
    ]
  }

  static get HOT_OR_NOT_ACTIONS () {
    return [
      EventManager.ACTION_HOT_OR_NOT_USER_INTERACTION,
      EventManager.ACTION_HOT_OR_NOT_CARD1,
      EventManager.ACTION_HOT_OR_NOT_CARD2,
      EventManager.ACTION_HOT_OR_NOT_CARD3,
      EventManager.ACTION_HOT_OR_NOT_CARD4,
      EventManager.ACTION_HOT_OR_NOT_RESULT,
      EventManager.ACTION_HOT_OR_NOT_HOT1,
      EventManager.ACTION_HOT_OR_NOT_HOT2,
      EventManager.ACTION_HOT_OR_NOT_HOT3,
      EventManager.ACTION_HOT_OR_NOT_HOT4,
      EventManager.ACTION_HOT_OR_NOT_NOT1,
      EventManager.ACTION_HOT_OR_NOT_NOT2,
      EventManager.ACTION_HOT_OR_NOT_NOT3,
      EventManager.ACTION_HOT_OR_NOT_NOT4,
      EventManager.ACTION_HOT_OR_NOT_SKIP
    ]
  }

  static get VIDEO_POLL_ACTIONS () {
    return [
      EventManager.ACTION_VIDEO_POLL_ANSWER_NOW_CLICK,
      EventManager.ACTION_VIDEO_POLL_OPEN_ANSWER_OVERLAY,
      EventManager.ACTION_VIDEO_POLL_ANSWER_SELECTED,
      EventManager.ACTION_VIDEO_POLL_USER_INTERACTION,
      EventManager.ACTION_VIDEO_POLL_ANSWER_1,
      EventManager.ACTION_VIDEO_POLL_ANSWER_2,
      EventManager.ACTION_VIDEO_POLL_ANSWER_3,
      EventManager.ACTION_VIDEO_POLL_ANSWER_4,
      EventManager.ACTION_VIDEO_POLL_ENDCARD,
      EventManager.ACTION_VIDEO_POLL_ENDCARD_CLICK,
      EventManager.ACTION_VIDEO_POLL_VIDEO_CLICK,
      EventManager.ACTION_VIDEO_POLL_VIDEO_FINISH,
      EventManager.ACTION_VIDEO_POLL_VIDEO_CLOSE,
      EventManager.ACTION_VIDEO_POLL_VIDEO_START,
      EventManager.ACTION_VIDEO_POLL_VIDEO_PLAY,
      EventManager.ACTION_VIDEO_POLL_VIDEO_PAUSE,
      EventManager.ACTION_VIDEO_POLL_VIDEO_RESUME,
      EventManager.ACTION_VIDEO_POLL_VIDEO_MUTE,
      EventManager.ACTION_VIDEO_POLL_VIDEO_UNMUTE,
      EventManager.ACTION_VIDEO_POLL_VIDEO_VTR_0,
      EventManager.ACTION_VIDEO_POLL_VIDEO_VTR_25,
      EventManager.ACTION_VIDEO_POLL_VIDEO_VTR_50,
      EventManager.ACTION_VIDEO_POLL_VIDEO_VTR_75,
      EventManager.ACTION_VIDEO_POLL_VIDEO_VTR_100
    ]
  }

  static get IMAGE_SEQUENCE_TRACKING () {
    return [
      EventManager.TRIGGER_IMAGE_SEQUENCE_LOADED,
      EventManager.TRIGGER_IMAGE_SEQUENCE_PLAY,
      EventManager.TRIGGER_IMAGE_SEQUENCE_PAUSE,
      EventManager.TRIGGER_IMAGE_SEQUENCE_REPEAT,
      EventManager.TRIGGER_IMAGE_SEQUENCE_FINISH
    ]
  }

  static get TRIGGER_CLICK () {
    return "click"
  }

  static get TRIGGER_HOVER () {
    return "hover"
  }

  static get TRIGGER_SCENE_CLICK () {
    return "scene_click"
  }

  static get TRIGGER_SWIPE_LEFT () {
    return "swipe_left"
  }

  static get TRIGGER_SWIPE_RIGHT () {
    return "swipe_right"
  }

  static get TRIGGER_SWIPE_UP () {
    return "swipe_up"
  }

  static get TRIGGER_SWIPE_DOWN () {
    return "swipe_down"
  }

  static get TRIGGER_LONGPRESS () {
    return "longpress"
  }

  static get TRIGGER_DROP () {
    return "drop"
  }

  static get TRIGGER_TIMEOUT () {
    return "after:" // Scheme: after:SECONDS_TIMEOUT
  }

  static get TRIGGER_WITH () {
    return (eventUuid, delay) => ["with", eventUuid, delay].join(":")
  }

  static get TRIGGER_MOUSE_OUT () {
    return "mouse_out"
  }

  static get TRIGGER_MOUSE_OVER () {
    return "mouse_over"
  }

  static get TRIGGER_WINDOW_ONLOAD () {
    return "onload"
  }

  static get TRIGGER_TARGET_ONLOAD () {
    return "target_onload"
  }

  static get TRIGGER_SCENE_ON_SCROLL () {
    return "target_onviewable"
  }

  static get TRIGGER_FIRST_USER_INTERACTION () {
    return "first_user_interaction"
  }

  // Module-related triggers
  static get UNIQUE_TRIGGERS () {
    return {
      [CatchGameModuleType]: {
        [EventManager.CATCH_GAME_STARTED]: "Catch game started",
        [EventManager.CATCH_GAME_PAUSE]: "Catch game paused",
        [EventManager.CATCH_GAME_FINISHED]: "Catch game finished",
        [EventManager.CATCH_GAME_CATCH_ITEM]: "Catch game catch item",
        [EventManager.CATCH_GAME_CATCH_GOOD_ITEM]: "Catch game catch good item",
        [EventManager.CATCH_GAME_CATCH_BAD_ITEM]: "Catch game catch bad item"
      },
      [VastVideoModuleType]: {
        [EventManager.VAST_VIDEO_START]: "Video start",
        [EventManager.VAST_VIDEO_FIRST_QUARTILE]: "Video VTR (25%)",
        [EventManager.VAST_VIDEO_MIDPOINT]: "Video VTR (50%)",
        [EventManager.VAST_VIDEO_THIRD_QUARTILE]: "Video VTR (75%)",
        [EventManager.VAST_VIDEO_COMPLETE]: "Video VTR (100%)",
        [EventManager.VAST_VIDEO_MUTE]: "Video mute",
        [EventManager.VAST_VIDEO_UNMUTE]: "Video unmute",
        [EventManager.VAST_VIDEO_PAUSE]: "Video pause",
        [EventManager.VAST_VIDEO_FULLSCREEN]: "Video fullscreen",
        [EventManager.VAST_VIDEO_EXIT_FULLSCREEN]: "Exit video fullscreen",
        [EventManager.VAST_VIDEO_SKIP]: "Video skip",
        [EventManager.VAST_VIDEO_REWIND]: "Video rewind"
      },
      [AssetModuleType]: {
        [EventManager.TRIGGER_DROP]: "Drop element in basket"
      },
      [BackgroundModuleType]: {
        [EventManager.TRIGGER_DROP]: "Drop element in basket"
      },
      [TypoModuleType]: {
        [EventManager.TRIGGER_DROP]: "Drop element in basket"
      },
      [SwiperGroupModuleType]: {
        [EventManager.GALLERY_SLIDE]: "Gallery slide",
        [EventManager.GALLERY_USER_INTERACTION]: "Gallery user interaction (slide change)",
        [EventManager.GALLERY_SLIDE_ONCE]: "Gallery slide (once)",
        [EventManager.GALLERY_USER_SLIDE]: "Gallery user slide",
        [EventManager.GALLERY_USER_SLIDE_ONCE]: "Gallery user slide (once)",
        [EventManager.GALLERY_BUTTON_PREV]: "Gallery button prev",
        [EventManager.GALLERY_BUTTON_NEXT]: "Gallery button next",
        [EventManager.GALLERY_SLIDE_PREV]: "Gallery slide prev",
        [EventManager.GALLERY_SLIDE_NEXT]: "Gallery slide next",
        [EventManager.GALLERY_USER_SLIDE_CHANGE]: "Gallery user slide change"
      },
      [LightweightSwiperGroupModuleType]: {
        [EventManager.GALLERY_SLIDE]: "Gallery slide",
        [EventManager.GALLERY_SLIDE_ONCE]: "Gallery slide (once)",
        [EventManager.GALLERY_USER_SLIDE]: "Gallery user slide",
        [EventManager.GALLERY_USER_SLIDE_ONCE]: "Gallery user slide (once)",
        [EventManager.GALLERY_BUTTON_PREV]: "Gallery button prev",
        [EventManager.GALLERY_BUTTON_NEXT]: "Gallery button next",
        [EventManager.GALLERY_USER_SLIDE_CHANGE]: "Gallery user slide change"
      },
      [PanoModuleType]: {
        [EventManager.PANO_ENGAGEMENT_LEFT]: "Pano engagement (left)",
        [EventManager.PANO_ENGAGEMENT_CENTER]: "Pano engagement (center)",
        [EventManager.PANO_ENGAGEMENT_RIGHT]: "Pano engagement (right)",
        [EventManager.PANO_ENGAGEMENT_TOP]: "Pano engagement (top)",
        [EventManager.PANO_ENGAGEMENT_BOTTOM]: "Pano engagement (bottom)",
        [EventManager.PANO_ENGAGEMENT_MIDDLE]: "Pano engagement (middle)",
        [EventManager.PANO_READY]: "Pano ready",
        [EventManager.PANO_USER_INTERACTION]: "Pano user interaction"
      },
      [VideoModuleType]: {
        [EventManager.VIDEO_FINISHED]: "Video finished",
        [EventManager.VIDEO_START]: "Video start",
        [EventManager.VIDEO_BLOCKED]: "Video blocked",
        [EventManager.VIDEO_PLAY]: "Play Video",
        [EventManager.VIDEO_PAUSE]: "Pause Video",
        [EventManager.VIDEO_RESUME]: "Resume Video",
        [EventManager.VIDEO_REPLAY]: "Replay Video",
        [EventManager.VIDEO_TIMESTAMP]: "Video Timestamp",
        [EventManager.VIDEO_MUTE]: "Mute Video",
        [EventManager.VIDEO_UNMUTE]: "Unmute Video",
        [EventManager.VIDEO_RESET]: "Reset Video",
        [EventManager.VIDEO_PLAYER_EXPAND]: "Enter fullscreen",
        [EventManager.VIDEO_PLAYER_COLLAPSE]: "Leave fullscreen",
        [EventManager.VIDEO_VTR_0]: "Video VTR (0%)",
        [EventManager.VIDEO_VTR_25]: "Video VTR (25%)",
        [EventManager.VIDEO_VTR_50]: "Video VTR (50%)",
        [EventManager.VIDEO_VTR_75]: "Video VTR (75%)",
        [EventManager.VIDEO_VTR_100]: "Video VTR (100%)",
        [EventManager.TRIGGER_DROP]: "Drop element in basket"
      },
      [ImageSequenceModuleType]: {
        [EventManager.TRIGGER_IMAGE_SEQUENCE_FINISH]: "Sequence finished",
        [EventManager.TRIGGER_IMAGE_SEQUENCE_PLAY]: "Sequence play",
        [EventManager.TRIGGER_IMAGE_SEQUENCE_REPEAT]: "Sequence repeat",
        [EventManager.TRIGGER_IMAGE_SEQUENCE_PAUSE]: "Sequence paused",
        [EventManager.TRIGGER_IMAGE_SEQUENCE_LOADED]: "Sequence loaded",
        [EventManager.TRIGGER_CLICK]: "On click",
        [EventManager.TRIGGER_HOVER]: "On hover"
      },
      [MatchModuleType]: {
        [EventManager.MATCH_USER_INTERACTION]: "Match user interaction",
        [EventManager.MATCH_POSITIVE_MATCHES_EQUAL_TO]: "Liked cards equal to",
        [EventManager.MATCH_NEGATIVE_MATCHES_EQUAL_TO]: "Disliked cards equal to",
        [EventManager.MATCH_SPECIFIC_CARDS_LIKED]: "Specific cards liked",
        [EventManager.MATCH_SPECIFIC_CARDS_DISLIKED]: "Specific cards disliked",
        [EventManager.MATCH_FINISHED]: "Match finished",
        [EventManager.MATCH_CARD_YES]: "Match card yes",
        [EventManager.MATCH_CARD_NO]: "Match card no",
        [EventManager.MATCH_TIMEOUT]: "Match timeout"
      },
      [VideoStoryModuleType]: {
        [EventManager.VIDEO_STORY_VIEW]: "Video Story view",
        [EventManager.VIDEO_STORY_FINISHED]: "Video Story finished",
        [EventManager.VIDEO_STORY_ON_SKIP_LEFT]: "Video Story skip left",
        [EventManager.VIDEO_STORY_ON_SKIP_RIGHT]: "Video Story skip right"
      },
      [StoryModuleType]: {
        [EventManager.STORY_VIEW]: "Story view",
        [EventManager.STORY_FINISHED]: "Story finished",
        [EventManager.STORY_ON_SKIP_LEFT]: "Story skip left",
        [EventManager.STORY_ON_SKIP_RIGHT]: "Story skip right"
      },
      [WipeAdModuleType]: {
        [EventManager.WIPE_START]: "Wipe start",
        [EventManager.WIPE_FINISHED]: "Wipe finished",
        [EventManager.WIPE_TIMEOUT]: "Wipe timeout"
      },
      [ParticleWipeAdModuleType]: {
        [EventManager.WIPE_START]: "Wipe start",
        [EventManager.WIPE_FINISHED]: "Wipe finished",
        [EventManager.WIPE_TIMEOUT]: "Wipe timeout"
      },
      [CalendarModuleType]: {
        [EventManager.CALENDAR_ICS_ADD]: "Calendar ICS added",
        [EventManager.CALENDAR_MRAID_ADD]: "Calendar ICS added (mraid)"
      },
      [SlidebarModuleType]: {
        [EventManager.SLIDEBAR_LEFT_OPEN]: "Left side open",
        [EventManager.SLIDEBAR_RIGHT_OPEN]: "Right side open",
        [EventManager.SLIDEBAR_MOVED_LEFT]: "Moved toward the left side",
        [EventManager.SLIDEBAR_MOVED_RIGHT]: "Moved toward the right side",
        [EventManager.SLIDEBAR_TIMEOUT]: "Timeout",
        [EventManager.SLIDEBAR_DRAG_IN_RANGE]: "Slidebar drag in the range",
        [EventManager.SLIDEBAR_RELEASED_IN_RANGE]: "Slidebar released in the range"
      },
      [PopupModuleType]: {
        [EventManager.POPUP_SHOWN]: "Popup shown",
        [EventManager.POPUP_HIDDEN]: "Popup hidden"
      },
      [BasketModuleType]: {
        [EventManager.BASKET_FULL]: "Basket filled",
        [EventManager.BASKET_DROP]: "Basket item added",
        [EventManager.BASKET_TIMEOUT]: "Basket timed out",
        [EventManager.BASKET_MISSED]: "Basket drop missed",
        [EventManager.BASKET_DRAG]: "Basket item drag start",
        [EventManager.BASKET_DRAG_ONCE]: "Basket item drag start (once)",
        [EventManager.BASKET_DRAGGED]: "Basket item was dragged"
      },
      [ShakeModuleType]: {
        [EventManager.SHAKE_SHAKED]: "On device shake",
        [EventManager.SHAKE_ONE]: "First device shake",
        [EventManager.SHAKE_TWO]: "Second device shake",
        [EventManager.SHAKE_THREE]: "Third device shake",
        [EventManager.SHAKE_FOUR]: "Fourth device shake",
        [EventManager.SHAKE_FINISHED]: "On shake finish",
        [EventManager.SHAKE_TIMEOUT]: "On shake timed out"
      },
      [WhatsappModuleType]: {
        [EventManager.WHATSAPP_SHARE]: "Share"
      },
      [Click2MapsModuleType]: {
        [EventManager.MAPS_DEFAULT]: "Clicks"
      },
      [PollModuleType]: {
        [EventManager.ACTION_POLL_RELEASED]: "Released",
        [EventManager.ACTION_POLL_LEFT]: "Left choice",
        [EventManager.ACTION_POLL_RIGHT]: "Right choice",
        [EventManager.ACTION_POLL_TIMEOUT]: "Timeout",
        [EventManager.ACTION_POLL_SLIDING_LEFT]: "Sliding left",
        [EventManager.ACTION_POLL_SLIDING_RIGHT]: "Sliding right"
      },
      [SurveyModuleType]: {
        [EventManager.ACTION_SURVEY_VOTE_SAVED]: "Vote saved",
        [EventManager.ACTION_SURVEY_VOTE_LEFT]: "Left choice",
        [EventManager.ACTION_SURVEY_VOTE_CENTER]: "Center choice",
        [EventManager.ACTION_SURVEY_VOTE_RIGHT]: "Right choice",
        [EventManager.ACTION_SURVEY_REVOTE]: "Revote"
      },
      [OfferistaModuleType]: {
        [EventManager.OFFERISTA_AUTO_SCROLL]: "Offerista auto scroll",
        [EventManager.OFFERISTA_PRODUCT_PV]: "Product __ID__ impression",
        [EventManager.OFFERISTA_PRODUCT_CLICK]: "Product __ID__ click",
        [EventManager.OFFERISTA_PRODUCT_UNIQUE_CLICK]: "Product __ID__ unique click",
        [EventManager.OFFERISTA_BANNER_PV]: "Banner __ID__ impression",
        [EventManager.OFFERISTA_BANNER_CLICK]: "Banner __ID__ click",
        [EventManager.OFFERISTA_BANNER_UNIQUE_CLICK]: "Banner __ID__ unique click",
        [EventManager.OFFERISTA_CATEGORY_CLICK]: "Category __ID__ click",
        [EventManager.OFFERISTA_CATEGORY_UNIQUE_CLICK]: "Category __ID__ unique click"
      },
      [CountdownModuleType]: {
        [EventManager.ACTION_COUNTDOWN_FINISHED]: "Countdown finished"
      },
      [HotOrNotModuleType]: {
        [EventManager.ACTION_HOT_OR_NOT_USER_INTERACTION]: "User interaction (hotornot)",
        [EventManager.ACTION_HOT_OR_NOT_RESULT]: "Result view",
        [EventManager.ACTION_HOT_OR_NOT_CARD1]: "card1 view",
        [EventManager.ACTION_HOT_OR_NOT_CARD2]: "card2 view",
        [EventManager.ACTION_HOT_OR_NOT_CARD3]: "card3 view",
        [EventManager.ACTION_HOT_OR_NOT_CARD4]: "card4 view",
        [EventManager.ACTION_HOT_OR_NOT_NOT1]: "card1 not",
        [EventManager.ACTION_HOT_OR_NOT_NOT2]: "card2 not",
        [EventManager.ACTION_HOT_OR_NOT_NOT3]: "card3 not",
        [EventManager.ACTION_HOT_OR_NOT_NOT4]: "card4 not",
        [EventManager.ACTION_HOT_OR_NOT_HOT1]: "card1 hot",
        [EventManager.ACTION_HOT_OR_NOT_HOT2]: "card2 hot",
        [EventManager.ACTION_HOT_OR_NOT_HOT3]: "card3 hot",
        [EventManager.ACTION_HOT_OR_NOT_HOT4]: "card4 hot",
        [EventManager.ACTION_HOT_OR_NOT_SKIP]: "skip",
        [EventManager.ACTION_HOT_OR_NOT_COMPLETE]: "complete"
      },
      [GoogleMapsModuleType]: {
        [EventManager.ACTION_MAP_ACTIVATED]: "Map activated"
      },
      [TimerModuleType]: {
        [EventManager.TIMER_START]: "Timer started",
        [EventManager.TIMER_STEP_25]: "Timer step 25%",
        [EventManager.TIMER_STEP_50]: "Timer step 50%",
        [EventManager.TIMER_STEP_75]: "Timer step 75%",
        [EventManager.TIMER_FINISHED]: "Timer finished",
        [EventManager.TIMER_TIMESTAMP]: "Timer timestamp"
      },
      [PuzzleModuleType]: {
        [EventManager.PUZZLE_DRAG_START]: "Puzzle drag started",
        [EventManager.PUZZLE_DRAG_CANCELED]: "Puzzle drag canceled",
        [EventManager.PUZZLE_DRAG_END]: "Puzzle drag finished",
        [EventManager.PUZZLE_USER_INTERACTION]: "Puzzle user interaction",
        [EventManager.PUZZLE_SWAPPED]: "Puzzle swapped",
        [EventManager.PUZZLE_COMPLETE]: "Puzzle completed",
        [EventManager.PUZZLE_TIMEOUT]: "Puzzle timeout"
      },
      [MemoryModuleType]: {
        [EventManager.MEMORY_WIN]: "Memory win",
        [EventManager.MEMORY_MATCH]: "Memory match",
        [EventManager.MEMORY_NO_MATCH]: "Memory no match",
        [EventManager.MEMORY_RESET]: "Memory reset",
        [EventManager.MEMORY_USER_INTERACTION]: "Memory user interaction",
        [EventManager.MEMORY_TIMEOUT]: "Memory timeout"
      },
      [SliderModuleType]: {
        [EventManager.SLIDER_USER_SLIDE]: "Slider user slide",
        [EventManager.SLIDER_USER_SLIDE_ONCE]: "Slider user slide (once)",
        [EventManager.SLIDER_RELEASED_0_50]: "Slider released 0-50%",
        [EventManager.SLIDER_RELEASED_50_100]: "Slider released 50-100%",
        [EventManager.SLIDER_DRAG_TO_THE_START]: "Slider drag to the start",
        [EventManager.SLIDER_DRAG_TO_THE_END]: "Slider drag to the end",
        [EventManager.SLIDER_DRAG_IN_RANGE]: "Slider drag in the range",
        [EventManager.SLIDER_RELEASED_IN_RANGE]: "Slider released in the range"
      },
      [CounterModuleType]: {
        [EventManager.COUNTER_ON_VALUE_CHANGE]: "Counter on value change"
      },
      [ThreeDModuleType]: {
        [EventManager.THREE_D_USER_INTERACTION]: "3D user interaction",
        [EventManager.THREE_D_ZOOM]: "3D zoom",
        [EventManager.THREE_D_ROTATE_HORIZONTAL]: "3D rotate horizontal",
        [EventManager.THREE_D_ROTATE_VERTICAL]: "3D rotate vertical"
      }
    }
  }

  static get TRIGGER_NAMES () {
    return Object.assign(
      {},
      EventManager.AVAILABLE_TRIGGERS,
      ...Object.values(EventManager.UNIQUE_TRIGGERS)
    )
  }

  static prepareEventAnimationBlock (event) {
    let start = 0
    if (event.trigger.startsWith("with:")) {
      start = parseInt(event.trigger.split(":")[2])
    }

    const isCustomAnimationEvent = event.action.hasOwnProperty(EventManager.ACTION_PLAY_CUSTOM_ANIMATION)
    const actionKey = isCustomAnimationEvent ? EventManager.ACTION_PLAY_CUSTOM_ANIMATION : EventManager.ACTION_PLAY_ANIMATION
    return Object.assign({
      easing: null,
      looped: false,
      loopDelay: 0,
      yoyo: false
    }, event.action[actionKey], {
      uuid: event.uuid,
      start,
      to: isCustomAnimationEvent ? event.action[EventManager.ACTION_PLAY_CUSTOM_ANIMATION].to : [],
      from: isCustomAnimationEvent ? event.action[EventManager.ACTION_PLAY_CUSTOM_ANIMATION].from : [],
      flipX: isCustomAnimationEvent ? event.action[EventManager.ACTION_PLAY_CUSTOM_ANIMATION].flipX : false,
      flipY: isCustomAnimationEvent ? event.action[EventManager.ACTION_PLAY_CUSTOM_ANIMATION].flipY : false,
      type: isCustomAnimationEvent ? EventManager.ACTION_PLAY_CUSTOM_ANIMATION : event.action[actionKey].animationName,
      createdAt: event.createdAt
    })
  }

  static get CLICKOUT () {
    return "clickout"
  }

  static get DUMMY () {
    return "dummy"
  }

  static get SHAKE_SHAKED () {
    return "shake_shaked"
  }

  static get VIDEO_TIMESTAMP () {
    return "video_timestamp"
  }

  static get TIMER_TIMESTAMP () {
    return "timer_timestamp"
  }

  static get TIMER_TIMESTAMP_TRACK () {
    return "timer_timestamp_track"
  }

  static get WHATSAPP_SHARE () {
    return "share"
  }

  static get MAPS_DEFAULT () {
    return "maps_default"
  }

  static get SHAKE_ONE () {
    return "shake_one"
  }

  static get SHAKE_TWO () {
    return "shake_two"
  }

  static get SHAKE_THREE () {
    return "shake_three"
  }

  static get SHAKE_FOUR () {
    return "shake_four"
  }

  static get SHAKE_START () {
    return "shake_start"
  }

  static get SHAKE_FINISHED () {
    return "shake_finished"
  }

  static get SHAKE_TIMEOUT () {
    return "shake_timeout"
  }

  static get BASKET_FULL () {
    return "basket_full"
  }

  static get BASKET_DROP () {
    return "basket_drop"
  }

  static get BASKET_MISSED () {
    return "basket_item_missed"
  }

  static get BASKET_DRAG () {
    return "basket_item_drag"
  }

  static get BASKET_DRAGGED () {
    return "basket_item_dragged"
  }

  static get BASKET_DRAG_ONCE () {
    return "basket_item_drag_once"
  }

  static get BASKET_TIMEOUT () {
    return "basket_timeout"
  }

  static get CALENDAR_MRAID_ADD () {
    return "calendar_mraid_add"
  }

  static get CALENDAR_ICS_ADD () {
    return "calendar_ics_add"
  }

  // Gallery module triggers
  static get GALLERY_IMPRESSION () {
    return "gallery_impression"
  }

  static get GALLERY_USER_SLIDE_ONCE () {
    return "gallery_user_slide_once"
  }

  static get GALLERY_USER_SLIDE () {
    return "gallery_user_slide"
  }

  static get GALLERY_SLIDE () {
    return "gallery_slide"
  }

  static get GALLERY_USER_INTERACTION () {
    return "gallery_user_interaction"
  }

  static get GALLERY_USER_INTERACTION_ONCE () {
    return "gallery_user_interaction_once"
  }

  static get GALLERY_SLIDE_ONCE () {
    return "gallery_slide_once"
  }

  static get GALLERY_SLIDE_EXIT () {
    return "gallery_changeslide_start"
  }

  static get GALLERY_SLIDE_ENTER () {
    return "gallery_changeslide_end"
  }

  static get GALLERY_BUTTON_PREV () {
    return "gallery_button_prev"
  }

  static get GALLERY_BUTTON_NEXT () {
    return "gallery_button_next"
  }

  static get GALLERY_SLIDE_PREV () {
    return "gallery_slide_prev"
  }

  static get GALLERY_SLIDE_NEXT () {
    return "gallery_slide_next"
  }

  static get GALLERY_USER_SLIDE_CHANGE () {
    return "gallery_user_slide_change"
  }

  // remember about appending index here
  static get GALLERY_SLIDE_X () {
    return "gallery_slide_"
  }

  static get TIMER_STEP_SECONDS_X () {
    return "timer_steps_seconds_"
  }

  // remember about appending index here
  static get BASKET_DROP_X () {
    return "basket_drop_%"
  }

  /** @deprecated **/
  static get GALLERY_CLICKOUT () {
    return "gallery_clickout"
  }

  // Wipe ad module
  static get WIPE_START () {
    return "wipe_start"
  }

  static get WIPE_FINISHED () {
    return "wipe_finished"
  }

  static get WIPE_TIMEOUT () {
    return "wipe_timeout"
  }

  // Pano module triggers
  static get PANO_ENGAGEMENT_LEFT () {
    return "pano_engagement_left"
  }

  static get PANO_ENGAGEMENT_CENTER () {
    return "pano_engagement_center"
  }

  static get PANO_ENGAGEMENT_RIGHT () {
    return "pano_engagement_right"
  }

  static get PANO_ENGAGEMENT_TOP () {
    return "pano_engagement_top"
  }

  static get PANO_ENGAGEMENT_BOTTOM () {
    return "pano_engagement_bottom"
  }

  static get PANO_ENGAGEMENT_MIDDLE () {
    return "pano_engagement_middle"
  }

  static get PANO_READY () {
    return "pano_ready"
  }

  static get PANO_USER_INTERACTION () {
    return "pano_user_interaction"
  }

  static get VIDEOPANO_PAUSE () {
    return "videopano_pause"
  }

  static get VIDEOPANO_RESUME () {
    return "videopano_resume"
  }

  static get VIDEOPANO_START () {
    return "videopano_start"
  }

  static get VIDEOPANO_PLAY () {
    return "videopano_play"
  }

  static get VIDEOPANO_REPLAY () {
    return "videopano_replay"
  }

  static get VIDEOPANO_MUTE () {
    return "videopano_mute"
  }

  static get VIDEOPANO_UNMUTE () {
    return "videopano_unmute"
  }

  static get VIDEOPANO_PLAYER_EXPAND () {
    return "videopano_player_expand"
  }

  static get VIDEOPANO_PLAYER_COLLAPSE () {
    return "videopano_player_collapse"
  }

  // Video module triggers
  static get VIDEO_FINISHED () {
    return "video_finished"
  }

  static get VIDEO_PAUSE () {
    return "video_pause"
  }

  static get VIDEO_RESUME () {
    return "video_resume"
  }

  static get VIDEO_START () {
    return "video_start"
  }

  static get VIDEO_BLOCKED () {
    return "video_blocked"
  }

  static get VIDEO_PLAY () {
    return "video_play"
  }

  static get VIDEO_REPLAY () {
    return "video_replay"
  }

  static get VIDEO_RESET () {
    return "video_reset"
  }

  static get VIDEO_MUTE () {
    return "video_mute"
  }

  static get VIDEO_UNMUTE () {
    return "video_unmute"
  }

  static get VIDEO_PLAYER_EXPAND () {
    return "video_player_expand"
  }

  static get VIDEO_PLAYER_COLLAPSE () {
    return "video_player_collapse"
  }

  static get VIDEO_STORY_FINISHED () {
    return "video_story_finished"
  }

  static get VIDEO_STORY_VIEW () {
    return "video_story_view"
  }

  static get VIDEO_STORY_ON_SKIP_LEFT () {
    return "video_story_on_skip_left"
  }

  static get VIDEO_STORY_ON_SKIP_RIGHT () {
    return "video_story_on_skip_right"
  }

  static get VIDEO_STORY_STEP_VTR () {
    return "video_story_step_vtr"
  }

  // remember about appending index here
  static get VIDEO_STORY_ON_STEP_X () {
    return "video_story_step_"
  }

  static get TIMER_START () {
    return "timer_start"
  }

  static get TIMER_STEP_25 () {
    return "timer_step_25"
  }

  static get TIMER_STEP_50 () {
    return "timer_step_50"
  }

  static get TIMER_STEP_75 () {
    return "timer_step_75"
  }

  static get TIMER_FINISHED () {
    return "timer_finished"
  }

  static get PUZZLE_DRAG_START () {
    return "puzzle_drag_start"
  }

  static get PUZZLE_DRAG_CANCELED () {
    return "puzzle_drag_canceled"
  }

  static get PUZZLE_DRAG_END () {
    return "puzzle_drag_end"
  }

  static get PUZZLE_USER_INTERACTION () {
    return "puzzle_user_interaction"
  }

  static get PUZZLE_SWAPPED () {
    return "puzzle_swapped"
  }

  static get PUZZLE_COMPLETE () {
    return "puzzle_complete"
  }

  static get PUZZLE_TIMEOUT () {
    return "puzzle_timeout"
  }

  static get THREE_D_USER_INTERACTION () {
    return "three_d_user_interaction"
  }

  static get THREE_D_ZOOM () {
    return "three_d_zoom"
  }

  static get THREE_D_ROTATE_VERTICAL () {
    return "three_d_rotate_vertical"
  }

  static get THREE_D_ROTATE_HORIZONTAL () {
    return "three_d_rotate_horizontal"
  }

  static get MEMORY_WIN () {
    return "win"
  }

  static get MEMORY_MATCH () {
    return "match"
  }

  static get MEMORY_NO_MATCH () {
    return "no_match"
  }

  static get MEMORY_RESET () {
    return "reset"
  }

  static get MEMORY_USER_INTERACTION () {
    return "user_interaction"
  }

  static get MEMORY_TIMEOUT () {
    return "memory_timeout"
  }

  static get ACTION_MEMORY_RESET () {
    return "actionMemoryReset"
  }

  static get ACTION_COMPLETE_MEMORY () {
    return "actionCompleteMemory"
  }

  static get ACTION_COMPLETE_PUZZLE () {
    return "complete_puzzle"
  }

  static get CATCH_GAME_CATCH_ITEM () {
    return "catch_game_catch_item"
  }

  static get CATCH_GAME_CATCH_GOOD_ITEM () {
    return "catch_game_catch_good_item"
  }

  static get CATCH_GAME_CATCH_BAD_ITEM () {
    return "catch_game_catch_bad_item"
  }

  static get CATCH_GAME_STARTED () {
    return "catch_game_started"
  }

  static get CATCH_GAME_TIMEOUT () {
    return "catch_game_timeout"
  }

  static get CATCH_GAME_PAUSE () {
    return "catch_game_paused"
  }

  static get CATCH_GAME_FINISHED () {
    return "catch_game_finished"
  }

  static get CATCH_GAME_LOST () {
    return "catch_game_lost"
  }

  static get ACTION_CATCH_GAME_START () {
    return "startCatchGame"
  }

  static get ACTION_CATCH_GAME_PAUSE () {
    return "pauseCatchGame"
  }

  static get ACTION_CATCH_GAME_FINISH () {
    return "finishCatchGame"
  }

  static get STORY_FINISHED () {
    return "story_finished"
  }

  static get STORY_VIEW () {
    return "story_view"
  }

  static get STORY_ON_SKIP_LEFT () {
    return "story_on_skip_left"
  }

  static get STORY_ON_SKIP_RIGHT () {
    return "story_on_skip_right"
  }

  // remember about appending index here
  static get STORY_ON_STEP_X () {
    return "story_step_"
  }

  static get VAST_VIDEO_START () {
    return "vast_video_start"
  }

  static get VAST_VIDEO_FIRST_QUARTILE () {
    return "vast_video_first_quartile"
  }

  static get VAST_VIDEO_MIDPOINT () {
    return "vast_video_midpoint"
  }

  static get VAST_VIDEO_SKIP () {
    return "vast_video_skip"
  }

  static get VAST_VIDEO_REWIND () {
    return "vast_video_rewind"
  }

  static get VAST_VIDEO_EXIT_FULLSCREEN () {
    return "vast_video_exit_fullscreen"
  }

  static get VAST_VIDEO_THIRD_QUARTILE () {
    return "vast_video_third_quartile"
  }

  static get VAST_VIDEO_COMPLETE () {
    return "vast_video_complete"
  }

  static get VAST_VIDEO_MUTE () {
    return "vast_video_mute"
  }

  static get VAST_VIDEO_UNMUTE () {
    return "vast_video_unmute"
  }

  static get VAST_VIDEO_PAUSE () {
    return "vast_video_pause"
  }

  static get VAST_VIDEO_FULLSCREEN () {
    return "vast_video_fullscreen"
  }

  static get CSV_GALLERY_VAST_VIDEO_START () {
    return "csv_gallery_vast_video_start"
  }

  static get CSV_GALLERY_VAST_VIDEO_FIRST_QUARTILE () {
    return "csv_gallery_vast_video_first_quartile"
  }

  static get CSV_GALLERY_VAST_VIDEO_MIDPOINT () {
    return "csv_gallery_vast_video_midpoint"
  }

  static get CSV_GALLERY_VAST_VIDEO_SKIP () {
    return "csv_gallery_vast_video_skip"
  }

  static get CSV_GALLERY_VAST_VIDEO_REWIND () {
    return "csv_gallery_vast_video_rewind"
  }

  static get CSV_GALLERY_VAST_VIDEO_EXIT_FULLSCREEN () {
    return "csv_gallery_vast_video_exit_fullscreen"
  }

  static get CSV_GALLERY_VAST_VIDEO_THIRD_QUARTILE () {
    return "csv_gallery_vast_video_third_quartile"
  }

  static get CSV_GALLERY_VAST_VIDEO_COMPLETE () {
    return "csv_gallery_vast_video_complete"
  }

  static get CSV_GALLERY_VAST_VIDEO_MUTE () {
    return "csv_gallery_vast_video_mute"
  }

  static get CSV_GALLERY_VAST_VIDEO_UNMUTE () {
    return "csv_gallery_vast_video_unmute"
  }

  static get CSV_GALLERY_VAST_VIDEO_PAUSE () {
    return "csv_gallery_vast_video_pause"
  }

  static get CSV_GALLERY_VAST_VIDEO_FULLSCREEN () {
    return "csv_gallery_vast_video_fullscreen"
  }

  /** @deprecated **/
  static get VIDEO_VTR () {
    return "video_vtr"
  }

  static get VIDEO_VTR_0 () {
    return "video_vtr_0"
  }

  static get VIDEO_VTR_25 () {
    return "video_vtr_25"
  }

  static get VIDEO_VTR_50 () {
    return "video_vtr_50"
  }

  static get VIDEO_VTR_75 () {
    return "video_vtr_75"
  }

  static get VIDEO_VTR_100 () {
    return "video_vtr_100"
  }

  static get VIDEOPANO_ENGAGEMENT () {
    return "videopano_engagement"
  }

  // Video module triggers
  static get VIDEOPANO_FINISHED () {
    return "videopano_finished"
  }

  /** @deprecated **/
  static get VIDEOPANO_VTR () {
    return "videopano_vtr"
  }

  static get VIDEOPANO_VTR_0 () {
    return "videopano_vtr_0"
  }

  static get VIDEOPANO_VTR_25 () {
    return "videopano_vtr_25"
  }

  static get VIDEOPANO_VTR_50 () {
    return "videopano_vtr_50"
  }

  static get VIDEOPANO_VTR_75 () {
    return "videopano_vtr_75"
  }

  static get VIDEOPANO_VTR_100 () {
    return "videopano_vtr_100"
  }

  // Slidebar module triggers
  static get SLIDEBAR_FIRST_INTERACTION () {
    return "slidebar_first_interaction"
  }

  static get SLIDEBAR_USER_SLIDE_ONCE () {
    return "slidebar_user_slide_once"
  }

  static get SLIDEBAR_LEFT_OPEN () {
    return "slidebar_left_open"
  }

  static get SLIDEBAR_RIGHT_OPEN () {
    return "slidebar_right_open"
  }

  static get SLIDEBAR_MOVED_LEFT () {
    return "slidebar_moved_left"
  }

  static get SLIDEBAR_MOVED_RIGHT () {
    return "slidebar_moved_right"
  }

  static get SLIDEBAR_TIMEOUT () {
    return "slidebar_timeout"
  }

  static get SLIDEBAR_DRAG_IN_RANGE () {
    return "slidebar_drag_in_range"
  }

  static get SLIDEBAR_DRAG_IN_RANGE_MIN () {
    return "slidebar_drag_in_range_min"
  }

  static get SLIDEBAR_DRAG_IN_RANGE_MAX () {
    return "slidebar_drag_in_range_max"
  }

  static get SLIDEBAR_DRAG_IN_RANGE_MIN_MAX () {
    return "slidebar_drag_in_range_min_max"
  }

  static get SLIDEBAR_RELEASED_IN_RANGE () {
    return "slidebar_released_in_range"
  }

  static get SLIDEBAR_RELEASED_IN_RANGE_MIN () {
    return "slidebar_released_in_range_min"
  }

  static get SLIDEBAR_RELEASED_IN_RANGE_MAX () {
    return "slidebar_released_in_range_max"
  }

  static get SLIDEBAR_RELEASED_IN_RANGE_MIN_MAX () {
    return "slidebar_released_in_range_min_max"
  }

  static get MATCH_USER_INTERACTION () {
    return "match_user_interaction"
  }

  static get MATCH_POSITIVE_MATCHES_EQUAL_TO () {
    return "match_positive_matches_equal_to"
  }

  static get MATCH_SPECIFIC_CARDS_LIKED () {
    return "match_specific_cards_liked"
  }

  static get MATCH_SPECIFIC_CARDS_DISLIKED () {
    return "match_specific_cards_disliked"
  }

  static get MATCH_NEGATIVE_MATCHES_EQUAL_TO () {
    return "match_negative_matches_equal_to"
  }

  static get MATCH_TIMEOUT () {
    return "match_timeout"
  }

  static get MATCH_CARD () {
    return "match_card"
  }

  static get MATCH_CARD_YES () {
    return "match_card_yes"
  }

  static get MATCH_CARD_NO () {
    return "match_card_no"
  }

  static get MATCH_CARD_X () {
    return "match_card_"
  }

  static get MATCH_CARD_YES_X () {
    return "match_card_yes_"
  }

  static get MATCH_CARD_NO_X () {
    return "match_card_no_"
  }

  static get MATCH_FINISHED () {
    return "match_finished"
  }

  static get SLIDER_USER_SLIDE () {
    return "slider_user_slide"
  }

  static get SLIDER_RELEASED_0_50 () {
    return "slider_released_0_50"
  }

  static get SLIDER_RELEASED_50_100 () {
    return "slider_released_50_100"
  }

  static get SLIDER_DRAG_TO_THE_START () {
    return "slider_drag_to_the_start"
  }

  static get SLIDER_DRAG_TO_THE_END () {
    return "slider_drag_to_the_end"
  }

  static get SLIDER_DRAG_IN_RANGE () {
    return "slider_drag_in_range"
  }

  static get SLIDER_DRAG_IN_RANGE_MIN () {
    return "slider_drag_in_range_min"
  }

  static get SLIDER_DRAG_IN_RANGE_MAX () {
    return "slider_drag_in_range_max"
  }

  static get SLIDER_DRAG_IN_RANGE_MIN_MAX () {
    return "slider_drag_in_range_min_max"
  }

  static get SLIDER_RELEASED_IN_RANGE () {
    return "slider_released_in_range"
  }

  static get SLIDER_RELEASED_IN_RANGE_MIN () {
    return "slider_released_in_range_min"
  }

  static get SLIDER_RELEASED_IN_RANGE_MAX () {
    return "slider_released_in_range_max"
  }

  static get SLIDER_RELEASED_IN_RANGE_MIN_MAX () {
    return "slider_released_in_range_min_max"
  }

  static get SLIDER_USER_SLIDE_ONCE () {
    return "slider_user_slide_once"
  }

  static get COUNTER_ON_VALUE_CHANGE () {
    return "counter_on_value_change"
  }

  static get COUNTER_ON_VALUE_CHANGE_OPERATOR () {
    return "counter_on_value_change_operator"
  }

  static get COUNTER_ON_VALUE_CHANGE_TRIGGER_ONCE () {
    return "counter_on_value_change_trigger_once"
  }

  // remember about appending index here
  static get SLIDER_USER_GALLERY_SLIDE_X () {
    return "slider_user_gallery_slide_"
  }

  static get POPUP_SHOWN () {
    return "popup_shown"
  }

  static get POPUP_HIDDEN () {
    return "popup_hidden"
  }

  static get OFFERISTA_AUTO_SCROLL () {
    return "offerista_auto_scroll_started"
  }

  static get OFFERISTA_PRODUCT_PV () {
    return "product___ID___pv"
  }

  static get OFFERISTA_PRODUCT_CLICK () {
    return "product___ID___cl"
  }

  static get OFFERISTA_PRODUCT_UNIQUE_CLICK () {
    return "product___ID___ucl"
  }

  static get OFFERISTA_BANNER_PV () {
    return "banner___ID___pv"
  }

  static get OFFERISTA_BANNER_CLICK () {
    return "banner___ID___cl"
  }

  static get OFFERISTA_BANNER_UNIQUE_CLICK () {
    return "banner___ID___ucl"
  }

  static get OFFERISTA_CATEGORY_CLICK () {
    return "category___ID___cl"
  }

  static get OFFERISTA_CATEGORY_UNIQUE_CLICK () {
    return "category___ID___ucl"
  }

  // Predefined actions
  static get ACTION_CHANGE_SCENE () {
    return "changeScene"
  }

  static get ACTION_CHANGE_SCENE_TRANSITIONS () {
    return "changeSceneTransitions"
  }

  static get ACTION_CHANGE_GROUP_VIEW () {
    return "changeGroupView"
  }

  static get ACTION_TRACK () {
    return "trackGoal"
  }

  static get ACTION_PLAY_VIDEO () {
    return "playVideo"
  }

  static get ACTION_START_IMAGE_SEQUENCE () {
    return "startImageSequence"
  }

  static get ACTION_PAUSE_IMAGE_SEQUENCE () {
    return "pauseImageSequence"
  }

  static get ACTION_RESET_IMAGE_SEQUENCE () {
    return "resetImageSequence"
  }

  static get TRIGGER_IMAGE_SEQUENCE_LOADING () {
    return "image_sequence_loading"
  }

  static get TRIGGER_IMAGE_SEQUENCE_LOADED () {
    return "image_sequence_loaded"
  }

  static get TRIGGER_IMAGE_SEQUENCE_READY () {
    return "image_sequence_ready"
  }

  static get TRIGGER_IMAGE_SEQUENCE_PLAY () {
    return "image_sequence_play"
  }

  static get TRIGGER_IMAGE_SEQUENCE_REPEAT () {
    return "image_sequence_repeat"
  }

  static get TRIGGER_IMAGE_SEQUENCE_FINISH () {
    return "image_sequence_finish"
  }

  static get TRIGGER_IMAGE_SEQUENCE_PAUSE () {
    return "image_sequence_pause"
  }

  static get ACTION_PAUSE_VIDEO () {
    return "pauseVideo"
  }

  static get ACTION_RESET_VIDEO () {
    return "resetVideo"
  }

  static get ACTION_PLAY_VIDEOPANO () {
    return "playPanoVideo"
  }

  static get ACTION_PLAY_ANIMATION () {
    return "playAnimation"
  }

  static get ACTION_PLAY_ANIMATION_ON_HOVER () {
    return "playAnimationOnHover"
  }

  static get ACTION_PLAY_CUSTOM_ANIMATION () {
    return "playCustomAnimation"
  }

  static get ACTION_PLAY_TIMELINE_SHEET () {
    return "playTimelineSheet"
  }

  static get ACTION_SCREEN_SHAKE () {
    return "actionScreenShake"
  }

  static get ACTION_RANDOM_SCENE_CHANGE () {
    return "randomSceneChange"
  }

  /** @deprecated **/
  static get ACTION_CHANGE_CLICKOUT () {
    return "changeClickout"
  }

  static get ACTION_SLIDEBAR_OPEN_LEFT () {
    return "slidebarOpenLeft"
  }

  static get ACTION_SLIDEBAR_OPEN_RIGHT () {
    return "slidebarOpenRight"
  }

  static get ACTION_INCREASE_COUNTER () {
    return "counterIncreaseBy"
  }

  static get ACTION_DECREASE_COUNTER () {
    return "counterDecreaseBy"
  }

  static get ACTION_SET_COUNTER () {
    return "counterSetValue"
  }

  static get COUNTER_ON_VALUE_CHANGE_TRACK () {
    return "counter_on_value_change_track"
  }

  static get ACTION_WIPE_PARTICLE_WIPE () {
    return "wipeParticleWipe"
  }

  static get ACTION_POLL_RELEASED () {
    return "poll_released"
  }

  static get ACTION_POLL_LEFT () {
    return "poll_left_choice"
  }

  static get ACTION_POLL_RIGHT () {
    return "poll_right_choice"
  }

  static get ACTION_POLL_TIMEOUT () {
    return "poll_timeout"
  }

  static get ACTION_POLL_SLIDING_LEFT () {
    return "poll_sliding_left"
  }

  static get ACTION_POLL_SLIDING_RIGHT () {
    return "poll_sliding_right"
  }

  static get ACTION_SURVEY_VOTE_SAVED () {
    return "survey_vote_saved"
  }

  static get ACTION_SURVEY_REVOTE () {
    return "survey_revote"
  }

  static get ACTION_SURVEY_VOTE_LEFT () {
    return "survey_vote_left"
  }

  static get ACTION_SURVEY_VOTE_CENTER () {
    return "survey_vote_center"
  }

  static get ACTION_SURVEY_VOTE_RIGHT () {
    return "survey_vote_right"
  }

  static get ACTION_FILTER_BY_CATEGORY () {
    return "offerista_filter_by_category"
  }

  static get ACTION_STOP_ANIMATION () {
    return "stop_animation"
  }

  static get ACTION_COUNTDOWN_FINISHED () {
    return "countdown_finished"
  }

  static get ACTION_MAP_ACTIVATED () {
    return "map_activated"
  }

  static get ACTION_TIMER_START () {
    return "initializeTimer"
  }

  static get ACTION_TIMER_ADD_TIME () {
    return "actionTimerAddTime"
  }

  static get ACTION_TIMER_STOP () {
    return "stopTimer"
  }

  static get ACTION_TIMER_RESET () {
    return "actionTimerReset"
  }

  static get TRACK_GOAL_TIMER_START () {
    return "timer_start"
  }

  /** Hot or not **/
  static get ACTION_HOT_OR_NOT_USER_INTERACTION () {
    return "user_interaction"
  }

  static get ACTION_HOT_OR_NOT_CARD1 () {
    return "card1"
  }

  static get ACTION_HOT_OR_NOT_CARD2 () {
    return "card2"
  }

  static get ACTION_HOT_OR_NOT_CARD3 () {
    return "card3"
  }

  static get ACTION_HOT_OR_NOT_CARD4 () {
    return "card4"
  }

  static get ACTION_HOT_OR_NOT_RESULT () {
    return "result"
  }

  static get ACTION_HOT_OR_NOT_HOT1 () {
    return "hot1"
  }

  static get ACTION_HOT_OR_NOT_HOT2 () {
    return "hot2"
  }

  static get ACTION_HOT_OR_NOT_HOT3 () {
    return "hot3"
  }

  static get ACTION_HOT_OR_NOT_HOT4 () {
    return "hot4"
  }

  static get ACTION_HOT_OR_NOT_NOT1 () {
    return "not1"
  }

  static get ACTION_HOT_OR_NOT_NOT2 () {
    return "not2"
  }

  static get ACTION_HOT_OR_NOT_NOT3 () {
    return "not3"
  }

  static get ACTION_HOT_OR_NOT_NOT4 () {
    return "not4"
  }

  static get ACTION_HOT_OR_NOT_DRAG_HOT1 () {
    return "drag_hot1"
  }

  static get ACTION_HOT_OR_NOT_DRAG_HOT2 () {
    return "drag_hot2"
  }

  static get ACTION_HOT_OR_NOT_DRAG_HOT3 () {
    return "drag_hot3"
  }

  static get ACTION_HOT_OR_NOT_DRAG_HOT4 () {
    return "drag_hot4"
  }

  static get ACTION_HOT_OR_NOT_BUTTON_HOT1 () {
    return "button_hot1"
  }

  static get ACTION_HOT_OR_NOT_BUTTON_HOT2 () {
    return "button_hot2"
  }

  static get ACTION_HOT_OR_NOT_BUTTON_HOT3 () {
    return "button_hot3"
  }

  static get ACTION_HOT_OR_NOT_BUTTON_HOT4 () {
    return "button_hot4"
  }

  static get ACTION_HOT_OR_NOT_DRAG_NOT1 () {
    return "drag_not1"
  }

  static get ACTION_HOT_OR_NOT_DRAG_NOT2 () {
    return "drag_not2"
  }

  static get ACTION_HOT_OR_NOT_DRAG_NOT3 () {
    return "drag_not3"
  }

  static get ACTION_HOT_OR_NOT_DRAG_NOT4 () {
    return "drag_not4"
  }

  static get ACTION_HOT_OR_NOT_BUTTON_NOT1 () {
    return "button_not1"
  }

  static get ACTION_HOT_OR_NOT_BUTTON_NOT2 () {
    return "button_not2"
  }

  static get ACTION_HOT_OR_NOT_BUTTON_NOT3 () {
    return "button_not3"
  }

  static get ACTION_HOT_OR_NOT_BUTTON_NOT4 () {
    return "button_not4"
  }

  static get ACTION_HOT_OR_NOT_COMPLETE () {
    return "complete"
  }

  static get ACTION_HOT_OR_NOT_SKIP () {
    return "skip"
  }

  static get ACTION_VIDEO_POLL_ANSWER_NOW_CLICK () {
    return "answer_now_click"
  }

  static get ACTION_VIDEO_POLL_OPEN_ANSWER_OVERLAY () {
    return "answer_overlay"
  }

  static get ACTION_VIDEO_POLL_ANSWER_SELECTED () {
    return "answer_selected"
  }

  static get ACTION_VIDEO_POLL_USER_INTERACTION () {
    return "user_interaction"
  }

  static get ACTION_VIDEO_POLL_ANSWER_1 () {
    return "answer_1"
  }

  static get ACTION_VIDEO_POLL_ANSWER_2 () {
    return "answer_2"
  }

  static get ACTION_VIDEO_POLL_ANSWER_3 () {
    return "answer_3"
  }

  static get ACTION_VIDEO_POLL_ANSWER_4 () {
    return "answer_4"
  }

  static get ACTION_VIDEO_POLL_ENDCARD () {
    return "endcard_show"
  }

  static get ACTION_VIDEO_POLL_ENDCARD_CLICK () {
    return "endcard"
  }

  static get ACTION_VIDEO_POLL_VIDEO_CLICK () {
    return "video"
  }

  static get ACTION_VIDEO_POLL_VIDEO_FINISH () {
    return "video_finish"
  }

  static get ACTION_VIDEO_POLL_VIDEO_CLOSE () {
    return "close"
  }

  static get ACTION_VIDEO_POLL_VIDEO_START () {
    return "start"
  }

  static get ACTION_VIDEO_POLL_VIDEO_PLAY () {
    return "play"
  }

  static get ACTION_VIDEO_POLL_VIDEO_PAUSE () {
    return "pause"
  }

  static get ACTION_VIDEO_POLL_VIDEO_RESUME () {
    return "resume"
  }

  static get ACTION_VIDEO_POLL_VIDEO_VTR_0 () {
    return "vtr0"
  }

  static get ACTION_VIDEO_POLL_VIDEO_VTR_25 () {
    return "vtr25"
  }

  static get ACTION_VIDEO_POLL_VIDEO_VTR_50 () {
    return "vtr50"
  }

  static get ACTION_VIDEO_POLL_VIDEO_VTR_75 () {
    return "vtr75"
  }

  static get ACTION_VIDEO_POLL_VIDEO_VTR_100 () {
    return "vtr100"
  }

  static get ACTION_VIDEO_POLL_VIDEO_MUTE () {
    return "mute"
  }

  static get ACTION_VIDEO_POLL_VIDEO_UNMUTE () {
    return "unmute"
  }

  static get ACTION_INITIALIZE_MAP () {
    return "initializeMap"
  }

  static get ACTION_PARTICLE_STOP () {
    return "stopEffect"
  }

  static get ACTION_PARTICLE_PLAY () {
    return "playEffect"
  }

  static get ACTIONS_WITH_MODULE_ID () {
    return [
      this.ACTION_CHANGE_GROUP_VIEW,
      this.ACTION_PLAY_VIDEO,
      this.ACTION_RESET_VIDEO,
      this.ACTION_PAUSE_VIDEO,
      this.ACTION_SLIDEBAR_OPEN_LEFT,
      this.ACTION_SLIDEBAR_OPEN_RIGHT,
      this.ACTION_INITIALIZE_MAP,
      this.ACTION_COMPLETE_PUZZLE,
      this.ACTION_COMPLETE_MEMORY,
      this.ACTION_PARTICLE_PLAY,
      this.ACTION_PARTICLE_STOP,
      this.ACTION_TIMER_START,
      this.ACTION_TIMER_STOP,
      this.ACTION_TIMER_RESET,
      this.ACTION_PLAY_VIDEOPANO,
      this.ACTION_MEMORY_RESET,
      this.ACTION_START_IMAGE_SEQUENCE,
      this.ACTION_PAUSE_IMAGE_SEQUENCE,
      this.ACTION_RESET_IMAGE_SEQUENCE,
      this.ACTION_INCREASE_COUNTER,
      this.ACTION_DECREASE_COUNTER,
      this.ACTION_TIMER_ADD_TIME,
      this.ACTION_SET_COUNTER,
      this.ACTION_CATCH_GAME_START,
      this.ACTION_CATCH_GAME_PAUSE,
      this.ACTION_CATCH_GAME_FINISH
    ]
  }

  static get AVAILABLE_ACTIONS () {
    return {
      [EventManager.ACTION_CHANGE_SCENE]: "Change scene",
      [EventManager.ACTION_CHANGE_GROUP_VIEW]: "Change group view",
      [EventManager.ACTION_TRACK]: "Track",
      [EventManager.ACTION_PLAY_VIDEOPANO]: "Play Pano Video",
      [EventManager.ACTION_PLAY_VIDEO]: "Play video",
      [EventManager.ACTION_PAUSE_VIDEO]: "Pause video",
      [EventManager.ACTION_RESET_VIDEO]: "Reset video",
      [EventManager.ACTION_SLIDEBAR_OPEN_LEFT]: "Open slidebar side left",
      [EventManager.ACTION_SLIDEBAR_OPEN_RIGHT]: "Open slidebar side right",
      [EventManager.ACTION_PLAY_ANIMATION]: "Play animation",
      [EventManager.ACTION_PLAY_ANIMATION_ON_HOVER]: "Hover-Animation",
      [EventManager.ACTION_PLAY_CUSTOM_ANIMATION]: "Play custom animation",
      [EventManager.ACTION_PLAY_TIMELINE_SHEET]: "Play custom animation", // The name is "fake" – this event actually
      [EventManager.ACTION_WIPE_PARTICLE_WIPE]: "Wipe particle wipe",
      [EventManager.ACTION_FILTER_BY_CATEGORY]: "Filter by offerista category",
      [EventManager.ACTION_STOP_ANIMATION]: "Stop animation",
      [EventManager.ACTION_TIMER_START]: "Start timer",
      [EventManager.ACTION_TIMER_STOP]: "Stop timer",
      [EventManager.ACTION_TIMER_RESET]: "Reset timer",
      [EventManager.ACTION_TIMER_ADD_TIME]: "Add seconds to timer",
      [EventManager.ACTION_COMPLETE_PUZZLE]: "Complete puzzle",
      [EventManager.ACTION_COMPLETE_MEMORY]: "Complete memory",
      [EventManager.ACTION_PARTICLE_PLAY]: "Play particle effect",
      [EventManager.ACTION_PARTICLE_STOP]: "Stop particle effect",
      [EventManager.ACTION_MEMORY_RESET]: "Reset memory",
      [EventManager.ACTION_SCREEN_SHAKE]: "Shake screen",
      [EventManager.ACTION_RANDOM_SCENE_CHANGE]: "Random scene change",
      [EventManager.ACTION_START_IMAGE_SEQUENCE]: "Play image sequence",
      [EventManager.ACTION_PAUSE_IMAGE_SEQUENCE]: "Stop image sequence",
      [EventManager.ACTION_RESET_IMAGE_SEQUENCE]: "Reset image sequence",
      [EventManager.ACTION_INCREASE_COUNTER]: "Increase counter",
      [EventManager.ACTION_DECREASE_COUNTER]: "Decrease counter",
      [EventManager.ACTION_SET_COUNTER]: "Set counter value",
      [EventManager.ACTION_CATCH_GAME_START]: "Start catch game",
      [EventManager.ACTION_CATCH_GAME_PAUSE]: "Pause catch game",
      [EventManager.ACTION_CATCH_GAME_FINISH]: "Finish catch game"
    }
  }

  static getEventForTimeoutTrigger (ms) {
    return EventManager.TRIGGER_TIMEOUT + ms
  }

  static getEventForVideoStoryStep (idx) {
    return EventManager.VIDEO_STORY_ON_STEP_X + idx
  }

  static getEventForStoryStep (idx) {
    return EventManager.STORY_ON_STEP_X + idx
  }

  static getEventForMatchCardYesX (idx) {
    return EventManager.MATCH_CARD_YES_X + idx
  }

  static getEventForMatchCardNoX (idx) {
    return EventManager.MATCH_CARD_NO_X + idx
  }

  static getEventForMatchCardX (idx) {
    return EventManager.MATCH_CARD_X + idx
  }

  static getEventNameForSlide (idx) {
    return EventManager.GALLERY_SLIDE_X + idx
  }

  static getEventNameForSliderUserGallerySlide (idx) {
    return EventManager.SLIDER_USER_GALLERY_SLIDE_X + idx
  }

  static getEventNameForMatchCard (idx) {
    return EventManager.MATCH_CARD_X + idx
  }

  static getEventNameForMatchCardYes (idx) {
    return EventManager.MATCH_CARD_YES_X + idx
  }

  static getEventNameForMatchCardNo (idx) {
    return EventManager.MATCH_CARD_NO_X + idx
  }

  static getTitleForSlide (idx) {
    return "Gallery on slide " + idx
  }

  static getTitleForMatchCard (idx) {
    return "Match card " + idx
  }

  static getTitleForMatchCardYes (idx) {
    return "Match card (yes) " + idx
  }

  static getTitleForMatchCardNo (idx) {
    return "Match card (no) " + idx
  }

  static getTitleForMatchCardX (idx) {
    return "On card " + idx
  }

  static getTitleForVideoStoryStep (idx) {
    return "Video story on step " + idx
  }

  static getTitleForStoryStep (idx) {
    return "Story on step " + idx
  }

  static getTitleForMatchCardYesX (idx) {
    return "Yes on card " + idx
  }

  static getTitleForMatchCardNoX (idx) {
    return "No on card " + idx
  }

  static getTitleForTimerSecondsStep (seconds) {
    return `Timer on ${seconds} mark`
  }

  static getEventNameForTimerSecondsStep (seconds) {
    return EventManager.TIMER_STEP_SECONDS_X + seconds
  }

  static getActionName (key) {
    return EventManager.AVAILABLE_ACTIONS[key]
  }

  static getActionTarget (event, modules, fieldName = "actionTarget") {
    let target = null
    modules.forEach((module) => {
      if (module.uuid === event[fieldName]) {
        target = module.name
      } else if (
        module.preview.group &&
        module.preview.group === event[fieldName]
      ) {
        target = "[Group] " + event[fieldName]
      }
    })
    return target
  }

  static getActionDetails (action, event, scenes, modules, design) {
    switch (action) {
      case EventManager.ACTION_CHANGE_CLICKOUT:
        return `URL: ${event.action[action].newClickout}`
      case EventManager.ACTION_CHANGE_SCENE:
        const scene = Utils.getSceneById(
          event.action[action].nextScene,
          scenes
        )
        if (scene) {
          const sceneName = scene.name
          return `Scene: ${sceneName}`
        } else {
          return "Scene: unknown"
        }
      case EventManager.ACTION_CHANGE_GROUP_VIEW:
        return `Group: ${event.action[action].groupNumber + 1}`
      case EventManager.ACTION_TRACK:
        if (design && !design.tracking.native) {
          return `URL: ${event.action[action].trackingUrl}`
        } else {
          return `Event: ${event.action[action].trackingUrl}`
        }
      case EventManager.ACTION_PLAY_VIDEO:
      case EventManager.ACTION_RESET_VIDEO:
      case EventManager.ACTION_PAUSE_VIDEO:
        return `Video: ${
          Utils.getModuleById(event.action[action].moduleId, modules).name
        }`
      case EventManager.ACTION_INITIALIZE_MAP:
        return `Map: ${
          Utils.getModuleById(event.action[action].moduleId, modules).name
        }`
      case EventManager.ACTION_PLAY_VIDEOPANO:
        return `: ${
          Utils.getModuleById(event.action[action].moduleId, modules).name
        }`
      case EventManager.ACTION_PLAY_ANIMATION:
        return `Animation: ${
          AnimationManager.ANIMATION_PRESETS_NAME[
            event.action[action].animationName
            ]
        }`
    }

    return ""
  }

  static getTriggerName (key, target, scenes, modules, events, design) {
    if (key && key.startsWith("after:")) {
      const name = `After ${key.split(":")[1] / 1000}s`

      if (target === "Global") {
        return `${name} globally`
      }
      let eventTarget = Utils.getSceneById(target, scenes)
      if (!eventTarget) {
        eventTarget = Utils.getModuleById(target, modules)
      }

      return `${name} in ${eventTarget.name}`
    } else if (key && key.startsWith("nointeraction:")) {
      return `After ${key.split(":")[1] / 1000}s without interaction ${
        target === "Global"
          ? "globally"
          : `in ${Utils.getSceneById(target, scenes).name}`
      }`
    } else if (key && key.startsWith("sync:")) {
      if (!events || !design) {
        return "After event"
      }
      const event = events.find((e) => e.uuid === key.split(":")[1])
      return `After: ${this.stringifyEvent(event, scenes, modules, design)}`
    } else if (key && key.startsWith("with:")) {
      const split = key.split(":")
      let result = "With event"
      if (split.length === 3 && parseInt(split[2]) > 0) {
        result += ` after ${parseInt(split[2]) / 1000}s`
      }

      return result
    } else if (key && key.startsWith(EventManager.GALLERY_SLIDE_X)) {
      const split = key.split("_")
      const idx = split[split.length - 1]

      return EventManager.getTitleForSlide(idx)
    } else if (key && key.startsWith(EventManager.SLIDER_USER_GALLERY_SLIDE_X)) {
      const split = key.split("_")
      const idx = split[split.length - 1]

      return EventManager.getTitleForSlide(idx)
    } else if (key && key.startsWith(EventManager.MATCH_CARD_YES_X)) {
      const split = key.split("_")
      const idx = split[split.length - 1]

      return EventManager.getTitleForMatchCardYes(idx)
    } else if (key && key.startsWith(EventManager.MATCH_CARD_NO_X)) {
      const split = key.split("_")
      const idx = split[split.length - 1]

      return EventManager.getTitleForMatchCardNo(idx)
    } else if (key && key.startsWith(EventManager.MATCH_CARD_X)) {
      const split = key.split("_")
      const idx = split[split.length - 1]

      return EventManager.getTitleForMatchCard(idx)
    } else if (key && key.startsWith(EventManager.TRIGGER_SCENE_ON_SCROLL)) {
      return "On scene scroll"
    } else if (key && key.startsWith(EventManager.TRIGGER_FIRST_USER_INTERACTION)) {
      return "On first user interaction"
    } else if (key && key.startsWith(EventManager.TRIGGER_SCENE_CLICK)) {
      return "On scene click"
    } else if (key && key.startsWith(EventManager.VIDEO_STORY_ON_STEP_X)) {
      const split = key.split("_")
      const idx = split[split.length - 1]

      return EventManager.getTitleForVideoStoryStep(idx)
    } else if (key && key.startsWith(EventManager.STORY_ON_STEP_X)) {
      const split = key.split("_")
      const idx = split[split.length - 1]

      return EventManager.getTitleForStoryStep(idx)
    } else {
      return EventManager.TRIGGER_NAMES[key]
    }
  }

  static getActionPlaceholders (action) {
    switch (action) {
      case EventManager.ACTION_CHANGE_SCENE:
        return {
          nextScene: null
        }
      case EventManager.ACTION_CHANGE_GROUP_VIEW:
        return {
          moduleId: null,
          groupNumber: 0
        }
      case EventManager.ACTION_TRACK:
        return {
          trackingUrl: null // This param will be omitted if native adtron tracking selected
        }
      case EventManager.ACTION_INITIALIZE_MAP:
        return {
          moduleId: null // The target google maps uuid
        }
      case EventManager.ACTION_PLAY_VIDEO:
        return {
          moduleId: null // The target video uuid
        }
      case EventManager.ACTION_PARTICLE_PLAY:
        return {
          moduleId: null // The target particle uuid
        }
      case EventManager.ACTION_PARTICLE_STOP:
        return {
          moduleId: null // The target particle uuid
        }
      case EventManager.ACTION_RESET_VIDEO:
        return {
          moduleId: null // The target video uuid
        }
      case EventManager.ACTION_PAUSE_VIDEO:
        return {
          moduleId: null // The target video uuid
        }
      case EventManager.ACTION_SLIDEBAR_OPEN_LEFT:
        return {
          side: "left",
          moduleId: null // The target slidebar uuid
        }
      case EventManager.ACTION_SLIDEBAR_OPEN_RIGHT:
        return {
          side: "right",
          moduleId: null // The target slidebar uuid
        }
      case EventManager.ACTION_PLAY_ANIMATION:
        return {
          animationName: "flash",
          easing: null,
          duration: 1000
        }
      case EventManager.ACTION_PLAY_CUSTOM_ANIMATION:
        return {
          from: {},
          to: {},
          easing: null,
          duration: 1000
        }
    }
  }

  static getAvailableTriggers (moduleType, events = []) {
    let triggers = EventManager.AVAILABLE_TRIGGERS

    if (moduleType === "Global") {
      const availableEvents = {}
      if (events.length) {
        availableEvents["sync:"] = "After specific event"
      }
      return {
        ...availableEvents,
        "nointeraction:1000": "After X seconds without interaction",
        "after:1000": "After X seconds", // Global timeout trigger only
        [EventManager.TRIGGER_SCENE_CLICK]: "On scene click",
        [EventManager.TRIGGER_SCENE_ON_SCROLL]: "On scene scroll",
        [EventManager.TRIGGER_FIRST_USER_INTERACTION]: "On first user interaction"
      }
    }

    if (moduleType && EventManager.UNIQUE_TRIGGERS.hasOwnProperty(moduleType)) {
      triggers = Object.assign(
        {},
        EventManager.UNIQUE_TRIGGERS[moduleType],
        triggers
      )

      // Filter offerista dynamic triggers
      if (moduleType === OfferistaModuleType) {
        triggers = Object.keys(triggers)
          .filter((key) => key.includes("___ID___") === false)
          .reduce((object, key) => {
            return Object.assign(object, {
              [key]: triggers[key]
            })
          }, {})
      }
    }
    return triggers
  }

  static stringifyEvent (e, scenes, modules, design) {
    if (!e) {
      return
    }

    const triggerName = EventManager.getTriggerName(e.trigger, e.target, scenes, modules)

    const actionName = EventManager.getActionName(Object.keys(e.action)[0]).toLowerCase()

    const actionDetails = EventManager.getActionDetails(Object.keys(e.action)[0], e, scenes, modules, design).toLowerCase()

    let actionTarget = EventManager.getActionTarget(e, modules, "target")

    if (!actionTarget) {
      actionTarget = scenes.find((scene) => scene.uuid === e.target)?.name ?? "Global"
    }

    const eventStructure = [
      triggerName,
      actionName,
      actionDetails ? `(${actionDetails})` : "",
      "on",
      actionTarget
    ]

    return eventStructure.join(" ")
  }

  static getAvailableTriggersOptions (moduleType, events = []) {
    const triggers = this.getAvailableTriggers(moduleType, events)

    const options = []
    for (const triggerKey in triggers) {
      if (triggers.hasOwnProperty(triggerKey)) {
        options.push({
          value: triggerKey,
          name: triggers[triggerKey],
          title: triggers[triggerKey]
        })
      }
    }

    return options
  }

  static getAvailableActions () {
    return EventManager.AVAILABLE_ACTIONS
  }

  static getAvailableActionsOptions () {
    const actions = EventManager.AVAILABLE_ACTIONS
    const availableActions = []
    for (const actionKey in actions) {
      availableActions.push({
        value: actionKey,
        name: actions[actionKey],
        title: actions[actionKey]
      })
    }

    return availableActions
  }

  static getFriendlySceneTrackId (sceneName) {
    if (typeof sceneName === "string") {
      return sceneName
        .replace(/[^a-zA-Z0-9_]+/i, "").toLowerCase()
    }
    return sceneName
  }

  static getFriendlyEventTrackId (module) {
    const splitUuid = module.uuid.split("-")
    const parsedId = splitUuid[splitUuid.length - 1]
    switch (module.type) {
      case SwiperGroupModuleType:
        return "gallery" + parsedId
      case LightweightSwiperGroupModuleType:
        return "lw-gallery" + parsedId
      case ShakeModuleType:
        return "shake"
      case ParticleWipeAdModuleType:
        return "particle_wipe" + parsedId
      case GoogleMapsModuleType:
        return "google_maps" + parsedId
      case VideoStoryModuleType:
        return "video_story" + parsedId
      case ImageSequenceModuleType:
        return "image_sequence" + parsedId
      case WipeAdModuleType:
        return "wipe" + parsedId
      case Click2MapsModuleType:
        return "click" // override for click.maps_default
    }

    return module.type.replace(/module/i, "") + parsedId
  }
}
